@import url("./font.scss");
$theme-color: #00ffff;
//font size
.roboto {
  font-family: "Roboto", sans-serif;
}

.c-black {
  color: #393939 !important;
}
.c-grey {
  color: #b6b9c3 !important;
}
.c-grey2 {
  color: #727272;
}
.c-blue {
  color: #072d8f;
}
.opacity-7 {
  opacity: 0.7;
}
.opacity-8 {
  opacity: 0.8;
}
.opacity-9 {
  opacity: 0.9;
}
.opacity-6 {
  opacity: 0.6;
}

.light-heading {
  font: normal normal 300 20px/76px Roboto;
  letter-spacing: 0.18px;
  color: #190835;
  margin: 0;
}
.dot.green {
  height: 5px;
  width: 5px;
  background: #1ecc1e;
  border-radius: 50%;
}
.footer_container {
  .footer_link {
    &:hover {
      color: $theme-color;
    }
  }
}
.card-img-container {
  padding: 20px;

  .property-card-img {
    box-shadow: 0px 2px 13px #0000001f;
    border-radius: 13px;
  }
}

.carousel-icon {
  box-shadow: 0px 7px 20px -4px #a7a7a7a8;
  border-radius: 20px;
}

.submit-btn {
  button {
    height: 56px;
    width: 180px;
  }
}
.app-stores {
  img {
    height: 45px;
  }
}

.linkDropDown {
  border: 0px !important;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
  }
}

.buyer-reschedule-btn {
  background: #1c0434;
  color: var(--text-color);
  // font-weight: 500;
  border-radius: var(--border-radius);
  border: 0;
  position: relative;
  padding: 8px 16px;
  border-radius: 8px;
  width: 100%;
}
.buyer-outline.mr-2 {
  padding: 8px 16px;
  border-radius: 8px;
  width: 100%;
  // font-weight: 500;
  border: 0.5px solid #b5b5b5;
  border-radius: 8px;
  background-color: transparent;
  color: #707070;
}

.slot-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #cdcdcd;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 24px;
  display: inline-flex;
  width: auto !important;
  margin: auto;
  margin-right: 15px;
  margin-top: 15px;
  &.active {
    border-color: #072d8f;
    color: #072d8f;
    font-weight: 500;
    box-shadow: 0px 1px 11px #1907351f;
  }
}

.form-check-input:checked {
  background-color: #072d8f;
  border-color: #072d8f;
}
.slot-model .modal-dialog {
  max-width: 420px;
}

.theme-btn {
  padding: 10px 25px;
  border: 1px solid;
  border-radius: 8px;
  &.black {
    background-color: #190835;
    color: #ffffff;
    border-color: #190835;
  }
  &.border {
    border: 1px solid #b5b5b5;
    color: #b5b5b5;
    background-color: transparent;
  }
}

.sent-img {
  width: 100px;
  margin-bottom: 20px;
}

.confirmDialog {
  .modal-dialog {
    width: 310px;
  }
}

.hero_content {
  h3 {
    font-family: Quart, sans-serif;
    font-size: 2.3rem;
    line-height: 4rem;
  }
  .sub-heading {
    font-size: 2rem;
    font-weight: 500;
  }
}

.form-control.shadow-input {
  box-shadow: 0px 3px 10px #00000017 !important;
  border-radius: 8px !important;
  padding-left: 20px !important;
  background-color: #ffffff !important;
}

.start-btn {
  width: 160px;
  height: 49px;
  font-size: 18px;
}

.video-preview {
  width: 100%;
  height: 100%;
}