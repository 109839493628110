.listingContainerBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
}
div {
  /* border: 1px solid red; */
}
.controllerContainer {
  width: 80%;
  margin: 50px;
  padding: 10px;
  border-radius: 10px;
}

.controllerBox {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.uploadButton {
  width: 175px;
  height: 37px;
  font-size: 20px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  /* border: 1x solid blue; */
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.soldButton {
  width: 135px;
  height: 37px;
  font-size: 20px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.activeButton {
  color: #151415;
  font-weight: 600;
  border-bottom: 2px solid #1c0434;
}

.nonActiveButton {
  color: #989696;
}

.content {
  width: 100%;
  height: 60%;
  /* background-color: red; */
}

.propertiesCardContainer {
  padding: 10px;
  margin: 10px 0px 10px 0px;
  height: 300px;
  border: 1px solid #dcdcdc8c;
  border-radius: 10px;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
}

.imageContainer {
  width: 50%;
  height: 100%;
  display: flex;
}

.propertiesCoverPic {
  flex: 7;
  padding: 4px;
  border-radius: 4px;
}

.pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.propertiesOtherMedia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;
}

.otherMedia {
  width: 100%;
  height: 50%;
  padding: 4px 4px 4px 0px;
  border-radius: 4px;
}

.propertiesDetailsContainer {
  width: 50%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-between;
}

.propertiesDetailBox {
  width: 100%;
  height: 60%;
}

.priceModalBox {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.propertiesOfferPrice {
  font-size: 27px;
  font-weight: 500;
  letter-spacing: 0.01px;
  color: #05d48b;
}

.modalController {
  border: 0.4px solid #dcdcdc8c;
  padding: 4px 6px 4px 6px;
  width: 30px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bullet {
  width: 4px;
  height: 4px;
  margin: 1px;
  border-radius: 50%;
  background-color: black;
}

.propertiesTypeText {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.01px;
  color: #231f1f;
}

.bookMarkViewContainer {
  display: flex;
}

.bookMarkViewBox {
  display: flex;
  align-items: center;
  padding: 4px;
  margin: 6px 6px 6px 0px;
}

.bookMarkViewBoxText {
  font-size: 16px;
  font-weight: 400;
  color: #5d5d5d;
}

.propertiesCardIcon {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.propertiesButtonContainer {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
}

.propertiesButton {
  color: #190835;
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0px 4px 0px 4px;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid #190835;
  border-radius: 8px;
}
.icon {
  width: 100%;
  height: 100%;
}

/* Modal Style */

.propertiesCardModalContainer {
  z-index: 2;
  background-color: white;
  min-width: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modalIconBox {
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
}

.modalItemBox {
  cursor: pointer;
  width: 100%;
  margin: 7px 6px 8px 6px;
  align-items: center;
  display: flex;
}
