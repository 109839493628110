body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  /* font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased; */
  /*-moz-osx-font-smoothing: grayscale; */
  font-family: "Roboto" !important;
}

@font-face {
  font-family: Quart;
  src: url("./OTF/Quart-Regular.otf");
  src: url("./OTF/Quart-Regular.eot");
  src: url("./OTF/Quart-Regular.eot?#iefix") format("embedded-opentype"),
    url("./OTF/Quart-Regular.woff2") format("woff2"),
    url("./OTF/Quart-Regular.woff") format("woff");
}

:root {
  --main-bg: #190835;
  --text-color: #fff;
  --button-color: #00ffff;
  --button2-color: #1c0434;
  --border-radius: 8px;
  --light-text: #0000004d;
  --head-text: #231f1f;
  --green-color: #1ef7ab;
  --input-color: #00000014;
  --login-text: #1c0434;
  --list-btn: #001978;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

input {
  outline: none;
}

a {
  text-decoration: none;
}

ul li {
  list-style: none;
}

ul li a {
  text-decoration: none;
}

.logo_img img {
  width: 100px;
  height: 94px;
  object-fit: contain;
}

.nav_bg {
  background: var(--main-bg);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: 0.9px solid #4e4163 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--text-color) !important;
  /* font-weight: 600; */
  margin: 0px 17px;
  font: normal normal bold 16px/34px "Roboto", sans-serif;
  transition: 0.4s;
  box-sizing: border-box;
  color: red;
  border-radius: 5px;
  position: relative;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.count_d {
  margin: 0 60px 0 60px;
}

.navbar-light .navbar-nav .nav-link::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 5px;
  border-radius: 50px;
  opacity: 0;
}

.navbar-light .navbar-nav .nav-link:hover::after {
  opacity: 1;
  /* background: var(--button-color);
  border-radius: 5px;
  transition: 0.4s;
  color: var(--main-bg) !important; */
}

.sticky-nav {
  position: sticky;
  top: 0;
}

.navbar-light .navbar-nav .nav-link:last-child:hover {
  background-color: transparent !important;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.login_btn {
  background: var(--button-color);
  font-weight: 600;
  border-radius: 8px;
  border: 0;
  width: 160px;
  height: 42px;
  box-sizing: border-box;
  font: normal normal bold 16px/34px "Roboto", sans-serif;
  /* padding: 14px 18px; */
  box-shadow: 0px 7px 35px #0000004d;
  letter-spacing: 0px;
  color: #001878;
  opacity: 1;
  /* font-size: 15px; */
}
.log22 {
  height: 56px !important;
}

.login_btn2 {
  width: 167px;
  height: 50px;
  position: relative;
  top: 10px;
}

/* hero_container */
.hero_container {
  background-color: var(--main-bg);
  /* background-image: url("./assets/images/header_img.png"); */
  background-image: url("https://ubsold.s3.us-east-2.amazonaws.com/images/a02cef4b464008dc8789f95df9d4eca98a63b373.png");
  background-repeat: no-repeat;
  background-position: 100%;
  background-position-y: 19px;
  background-size: contain;
  position: relative;
  color: var(--text-color);
  /* padding: 70px 0 70px 84px; */
}

.herobanner {
  background-color: var(--main-bg);
}

.hero_content {
  padding: 80px 0px 70px 0px;
}

.hero_container h1 {
  /* font-weight: 100 !important; */
  font-size: 3.4rem;
  line-height: 5rem;
  font-family: Quart, sans-serif;
}

.hero_container span {
  font-weight: 700;
  font-family: Quart, sans-serif;
  font-size: 1.4rem;
}

.donwload_app {
  /* display: flex; */
  /* width: fit-content; */
  flex-direction: column;
}

/* serarch  */
.search_container,
.near_property {
  margin: 50px;
}

.input_and_button {
  box-shadow: 0px 2px 30px #0000001a;
  box-shadow: 10px;
  border-radius: 104px;
  height: 60px;
  background: #fff;
  width: 70%;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  margin: auto;
}

.input_and_button input {
  height: -webkit-fill-available;
  border: none;
  outline: none;
  padding-left: 26px;
  width: 100%;
  border-radius: 100px;
}

.input_and_button button.active {
  border: none;
  padding: 8px 30px;
  background: #1c0434;
  color: #fff;
  /* margin-left: auto; */
  margin-left: 10px;
  border-radius: 46px;
}

.input_and_button button.not-active {
  border: none;
  padding: 8px 30px;
  border: 1.5px solid #1c0434;
  background: transparent;
  color: #1c0434;
  /* margin-left: auto; */
  margin-left: 10px;
  border-radius: 46px;
}

.guid_container {
  /* background-image: url("assets/images/Group18737.png"); */
  background-image: url("https://ubsold.s3.amazonaws.com/images/37db012c09c1d1b387eabcd4eb4f4e47e6ea1a64.png");
  min-height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
}

.guid_container2 {
  height: 70vh;
}

.guid_text {
  color: var(--text-color);
  font: normal normal bold 18px/19px "Roboto", sans-serif;
  /* font-weight: 700; */
}

.property_card {
  display: flex;
  overflow-x: scroll;
}

.card_style {
  width: 17rem !important;
  box-shadow: 0px 2px 30px #00000017;
  opacity: 1;
  margin: 10px;
  /* padding: 10px; */
  border: 0.1px solid #fff !important;
  border-radius: 12px !important;
}

.light_text {
  color: #111;
  font-size: 13px !important;
  letter-spacing: 0px;
  color: #5d5d5d;
  opacity: 1;
  font-weight: 400 !important;
  font-family: "Roboto";
}
.bottom_cards .head_text2 {
  font: normal normal bold 18px/22px "Roboto" !important;
  margin-bottom: 10px;
}
.bottom_cards .light_text {
  font: normal normal 300 14px/17px "Roboto";
  letter-spacing: 0px;
  color: #5d5d5d;
}
.head_text {
  color: var(--head-text);
  font-size: 18px !important;
}

a.property_btn {
  width: max-content !important;
}

a.property_btn,
.logIn_btn {
  background: var(--button2-color);
  color: var(--text-color);
  font-weight: 600;
  border-radius: var(--border-radius);
  border: 0;
  width: 28%;
  padding: 8px 32px;
  text-align: center;
}

.listing_action_btn a {
  text-decoration: none;
}

.property_btn {
  border: none;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  color: #fff;
  background: #190835;
}

.property_card_body {
  padding-top: 0 !important;
}

.near_head_tile,
.view_all {
  padding-left: 13px;
  color: #190735;
  font-size: 20px;
}

.view_all {
  text-align: right;
  padding-right: 13px;
}

.arrow_btn img {
  border-radius: var(--border-radius);
  border: 1px solid var(--light-text);
  padding: 5px;
  width: 20px;
  margin-left: 10px;
}

.arrow_btn span img {
  width: 7px;
}

.login_info span {
  font-weight: 700;
  font-family: Quart, sans-serif;
  font-size: 1.5rem;
}

.btn2 {
  background: #170632;
  color: var(--text-color);
  margin-left: 50px;
  font: normal normal bold 16px/34px "Roboto", sans-serif;
  width: 160px !important;
  height: 42px !important;
  box-shadow: 0px 7px 35px #0000004d !important;
}

/* footer  */
.footer_container {
  background: var(--main-bg);
  padding: 60px 70px 20px 70px;
}

.p-0.m-0.footer_container_wrapper.row {
  padding-bottom: 40px !important;
}

.footer_container ul li {
  margin: 8px 0;
  width: max-content;
}

.footer_container ul li span {
  color: var(--text-color);
  /* font-size: 1.5rem; */
  /* font-weight: 500; */
  font: normal normal 500 1.5rem/59px "Roboto", sans-serif;
}

.footer_link {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0px;
}

ul.app-stores {
  display: flex;
  gap: 10px;
  margin: 0;
}

.login_info span,
.hero_container span {
  display: flex;
  align-items: center;
  gap: 0;
}

.footer_copyright_section {
  color: #707070;
  padding-top: 15px;
  border-top: 1.2px solid #707070;
}

.footer_copyright_section {
  margin-top: 20px;
}

.social_icon img {
  margin: 0 10px;
}

.social_icon img:last-child {
  margin-right: 0 !important;
}

.price_text {
  color: var(--green-color);
  letter-spacing: 0.01px;
  opacity: 1;
  font-size: 36px;
  margin: 0;
  font-weight: 500;
}

span.listed-tag {
  position: absolute;
  border-radius: 3px;
  opacity: 1;
  background: #372cdc;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  top: 10px;
  left: 10px;
}

.video_wrapper {
  /* background: url("assets/images/VIDEO.png"); */
  background: url("https://ubsold.s3.us-east-2.amazonaws.com/images/ac04366b1343b6762b0ca60c326aa1da1f059dfa.png");
  background-repeat: no-repeat;
  background-size: cover;
  max-height: max-content;
  padding: 80px 0;
  height: 100vh;
}
.login_modal .modal-dialog {
  border: none;
  margin: auto;
  max-width: 400px;
}
.login_modal2 .modal-dialog {
  border: none;
  margin: auto;
  max-width: 470px;
}

.login_modal .modal-content {
  border-radius: 24px;
}

.login_modal .modal-header {
  border: none;
}

.login_modal .modal-header .modal-title {
  font-weight: 600;
  font-size: 1.3rem;
  font: normal normal bold 24px/45px "Roboto";
}

.close_btn {
  padding: 4px;
  background: #000;
  display: flex;
  border-radius: 100px;
  position: absolute;
  /* right: 10px;
  top: 13px; */

  right: 19px;
  top: 17px;
}

.login_modal .modal-header .btn-close {
  margin: 0;
  padding: 0;
  opacity: 1;
}

input.inputField {
  padding: 8px 0 8px 40px;
  outline: none;
  height: 49px;
  border-radius: 8px;
  color: #636363;
}

.search-icon {
  position: absolute;
  /* right: 5px; */
  top: 5px;
  z-index: 9999;
  /*this will keep the icon appearing all time, even when on input::focus*/
}

.inputField.form-control {
  border: none;

  background: var(--input-color);
}

.inputField.form-control:focus {
  border: none;
  background: var(--input-color);
  outline: none;
  box-shadow: none;
}

.forgot_password {
  display: flex;
  font-weight: 600;
  color: var(--login-text);
  margin: 10px 0;
  font-size: 14px;
  justify-content: end;
}

.input_label {
  color: var(--main-bg);
  font-size: 14px;
  font-weight: 600;
}

.logIn_btn {
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 15px;
  width: 100%;
}

.social_btn_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.social_btn_wrapper .social_btn {
  border: none;
  padding: 10px 50px;
  box-sizing: border-box;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  background: #2b52a7;
  opacity: 1;
}

/* for stepper */
.stepper_wrapper .StepButton-d6-0-2-18.active,
.stepper_wrapper .StepButton-d10-0-2-32.active,
.stepper_wrapper .StepButton-d2-0-2-8.active {
  border: 1.4px solid #001978;
}

.list_title {
  color: #393939;
  letter-spacing: 0.28px;
  font-weight: 400;
  font-family: Quart, sans-serif;
  margin-top: 35px;
  margin-bottom: 35px;
  font-size: 40px;
}

.card_name_title {
  margin: 0;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  font-size: 18px;
  font-family: "Roboto";
}

.nameappoint p {
  margin: 0;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  font-size: 13px;
}

.flex-user img {
  filter: drop-shadow(0px 6px 11px #0000001c);
}

.flex-user {
  display: flex;
  gap: 10px;
}

.head_text2 {
  font-size: 1rem !important;
}

.list_start {
  padding: 0 100px;
}

.list_heading {
  font-size: 1.1rem;
  font-weight: 600;
}

.appoint2ment_heading {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1c0434;
  margin: 20px 0;
}

.box_shadow_wrapper {
  width: 100%;
  border-radius: 5px;
  padding: 40px 0 20px 0;
  margin: 20px auto 70px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.home_type {
  margin: 16px 0;
}

.style_btn,
.style_btn2 {
  /* background: var(--list-btn); */
  background: #1c0434 0% 0% no-repeat padding-box;
  color: var(--text-color);
  font-weight: 500;
  border-radius: var(--border-radius);
  border: 0;
  position: relative;
  margin-right: 15px;
  padding: 12px 16px;
  border-radius: 8px;
}
.style_btn22 {
  /* background: var(--list-btn); */
  background: #001978 0% 0% no-repeat padding-box;
  color: var(--text-color);
  font-weight: 500;
  border-radius: var(--border-radius);
  border: 0;
  position: relative;
  margin-right: 10px;
  padding: 12px 16px;
  border-radius: 8px;
}

.appointment_wrapper .style_btn,
.appointment_wrapper .style_btn2 {
  margin-right: 0px !important;
}
.style_btn_div {
  /* background: #F8F8F8 !important; */
  /* width: max-content; */
  /* display: flex; */
  padding: 16px 0px;
  align-items: center;
  border-radius: 8px;
  /* justify-content: space-between; */
}
.style_btn_div323 {
  background: #f8f8f8 !important;
  width: max-content;
  display: flex;
  padding: 0 10px;
  align-items: center;
  border-radius: 8px;
  justify-content: space-between;
}
.style_btn::after {
  /* content: '';
  position: absolute;
  height:24px;
  top: 0;
  background: #F8F8F8 !important;
  width:5px */
}

p.list_heading {
  margin: 0;
  color: #000000;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.17px;
  opacity: 1;
  /* font-family: 'Roboto'; */
}

.additional-features div {
  width: 170px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}

.solartype p.list_heading {
  margin-bottom: 14px;
}

.style_btn2 {
  background-color: #f4f4f4;
  color: #636363;
  font-weight: 400;
}

.additional_feature {
  width: max-content;
  padding: 10px;
  border-radius: 8px;
  color: #190735;
  margin-right: 12px;
  font-weight: 500;
  border: 0.30000001192092896px solid #0019784f;
  box-shadow: 0px 2px 7px #1907351f;
}

.additional_feature label {
  letter-spacing: 0.34px;
  color: #190735;
  opacity: 1;
  font-size: 17px;
  padding-left: 9px;
  line-height: 20px;
}
.additional_feature2 label {
  padding-left: 9px;
}
.tedsdd {
  border-right: 1px solid #cecbcb;
  display: flex;
  height: 35px;
  background: #f3f3f3;
  margin: 0px 13px;
}
.additional_feature2 {
  width: max-content;
  padding: 10px;
  border-radius: 8px;
  color: #190735;
  margin-right: 12px;
  font-weight: 500;
  border: 0.30000001192092896px solid #cdcdcd;
}

input#ck-button,
input#ck-button2,
input#ck-button3,
input#ck-button4 {
  transform: scale(1.5);
  margin-right: 10px;
  border: none !important;
}

.style_btn_div .input-group {
  width: 40% !important;
}

.style_btn_div .input-group input {
  padding-left: 10px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
}
.style_btn_div .style_btn {
  font-weight: 400;
}
.style_btn_div button {
  font-weight: 300;
}
.tag_btn {
  padding: 7px 15px;
  border: 0.30000001192092896px solid #cdcdcd;
  border-radius: 50px;
  margin-top: 10px;
  width: max-content;
  display: flex;
  align-content: center;
  justify-content: space-between;
  position: relative;
  letter-spacing: 0.3px;
  color: #190735c2;
  opacity: 1;
  font-size: 15px;
  font-weight: 500;
}

.tag_btn img {
  margin-left: 10px;
}
span.tag_btn svg {
  position: absolute;
  right: 10px;
  top: 12px;
  background: #011a79;
  fill: #fff;
  border-radius: 50px;
}

.yes_btn,
.no_btn {
  width: 8rem;
}

.sdp--date-btn__selected {
  background-color: #072d8f !important;
}

/* .appointment_wrapper {
  padding: 0 120px;
} */

.msuic-upload .delete-cola {
  position: absolute;
  right: 14px;
}

.div-overlay-grey {
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(1, 1, 1, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.playicon {
  background: #ffffffa1;
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  height: 60px;
  width: 60px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment_content {
  /* display: flex;
  flex-wrap: wrap;
  gap: 10px; */
  width: 100%;
  overflow-x: scroll;
}

.appointment_card {
  border: 1px solid #70707033;
  border-radius: 14px;
  color: #3a3a3a;
  padding: 20px;
  flex: auto;
  min-width: 358px;
  max-width: 358px;
  /* width: 430px; */
  justify-content: space-between;
}

.appointment_content ul {
  padding: 0;
  display: flex;
  gap: 16px;
}

.top_card {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}

.appointment_btn {
  width: 100%;
  height: 42px;
  background: none;
  border: 1px solid #70707033;
  margin-top: 15px !important;
  font: normal normal normal 16px/19px "Roboto";
  letter-spacing: 0.18px;
  border-radius: 14px;
}

.appointment_right_wrapper,
.appointment_left_wrapper {
  padding: 35px !important;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.selected_date_title {
  font-size: 1.2rem;

  margin: 20px 20px 0 20px;
  font-weight: 600;
}

.sdp,
.stp {
  box-shadow: none !important;
}

.sdp--square-btn {
  border-radius: 10rem !important;
}

.appointment_left_wrapper {
  padding: 0 !important;
  height: max-content;
}

.seller_container {
  display: flex;
  gap: 30px;
  padding: 70px 0;
}

.profile_left_side {
  width: 360px;
  height: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}

.bottom_arrow img {
  width: 14px;
  height: 14px;
  margin-left: 5px;
  transform: rotate(-90deg);
}

.faq_section,
.services_section {
  margin: 80px 0;
}

.faq_section input {
  padding-left: 10px;
}

.faq_section h1 {
  font-size: 2.5rem;
  color: #393939;
}

.search_container {
  text-align: center;
}

.or_option_text_faq {
  color: var(--light-text);
}

.blue_search_div {
  padding: 7px;
  display: flex;
  background: #001878 0% 0% no-repeat padding-box;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
}

.faq_box_section {
  padding: 0 150px !important;
}

.profile_top_card {
  width: 360px;
  position: relative;
  border-radius: 10px 10px 0 0;
  background: rgb(51, 153, 255);
  background: linear-gradient(
    155deg,
    rgba(51, 153, 255, 1) 27%,
    rgb(13 81 149) 61%
  );
  /* background: #3399FF; */
  /* background: #823FEB; */
  height: 117px;
}

.profile_img_div {
  margin: -40px auto 0 auto;
  position: relative;
  z-index: 2;
  text-align: center;
  width: auto;
}

.profile_img_div p {
  margin-bottom: 0;
}

.profile_sub_title {
  font-size: 16px;
  color: #190735;
  opacity: 20%;
}

.profile_title {
  color: #190735;
  width: auto;
  margin-top: 10px;
  font-weight: 500;
  font-size: 1rem;
}

.profile_img_div img {
  width: 80px;
  height: 80px;

  border-radius: 50px;
  border: 0.2rem solid #fff;
}

.menu_list {
  display: flex;
  text-decoration: none;
  color: #636363;
  font-size: 0.9rem;
  margin: 20px 0;
  align-items: center;
}

.profile_menu {
  padding: 0 30px;
  margin-top: 20px;
}

.profile_menu hr {
  background: #190735;
  opacity: 10%;
  height: 1.5px;
}

.profile_menu ul {
  padding: 0;
}

.menu_list li span {
  display: inline-block;
  padding-left: 15px !important;
  font-weight: 600;
  color: #636363;
}

.profile_right_side {
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
  padding: 50px;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}

.count {
  font-size: 1.7rem;
  font-weight: 500;
  color: #001978;
}

.dashborad_info {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.listing_card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: max-content;
}

.box1 {
  width: 164px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 22px;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  flex: auto;
  color: #131212;
  background: #f8f8f8;
  height: 159px;
}

.dashboard_text {
  font-size: 1.5rem;
  font-weight: 700;
}

.box1.active_box .count {
  color: #fff;
}

.box1.active_box {
  background: #3399ff 0% 0% no-repeat padding-box;
  color: #fff;
}

.uploaded_tab {
  display: flex;
}

.uploaded_tab .uploading_tab_header {
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 20px;
  padding-bottom: 15px;
  margin-right: 20px;
}

.uploading_tab_header:first-child {
  border-bottom: 2px solid #151415;
}

.listing_texts {
  display: flex;
  border: 1px solid #dcdcdc8c;
  padding: 12px;
  border-radius: 10px;
}

.listing_texts img {
  width: 274px;
  height: 200px;
  object-fit: cover;
}

.listing_right {
  padding-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.listing_subtitle {
  font-size: 0.9rem;
  color: #5d5d5d;
  margin: 4px 0;
  display: flex;
  align-items: center;
  gap: 4px;
}

.listing_action_btn {
  margin-top: 12px;
}

.bhk_title {
  font-size: 1rem;
  margin: 10px 0;
  color: #231f1f;
  font-weight: 600;
}

.listing_action_btn button {
  width: 176px;
  border-radius: 8px;
  height: 48px;
}

.listing_action_btn a.not_active {
  border: 1px solid #190835;
  background: none;
  color: #190835;
}

.faq_container {
  width: 100%;
}

.faq_search input {
  height: 50px;
  width: 100%;
  color: #d2d2d2;
  padding-left: 20px;
  border: 1px solid #bcbcbc;
  border-radius: 9px;
}

.accordion_section {
  margin: 20px 0px;
}

.accordion_section .accordion .accordion-item {
  border-radius: 14px;
  border: none;
  margin-bottom: 20px;
}

.accordion_section .accordion .accordion-item .accordion-header {
  border: none;
}

.accordion_section .accordion .accordion-item .accordion-header button {
  background: #f2f2f2;
  box-shadow: none;
  color: #322f2f;
  border-radius: 14px 14px;
}

.accrodion_text {
  text-align: justify;
  margin: auto;
  padding: 14px;
  background: #f9f9f9;
  border-radius: 14px;
}

.contact_heading {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.contact_text {
  width: 213px;
  height: 20px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 16px/34px "Roboto";
  letter-spacing: 0px;
  color: #6f6f6f;
}
.contact_us .contact_text {
  width: 98% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #131212 !important;
  margin: 30px 0;
}
.contact_input {
  margin: 30px 0;
}

.contact_input input {
  width: 335px;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 7px;
  height: 50px;
  border: none;
  padding-left: 20px;
}

.contact_submit,
.contact_submit2 {
  width: 160px;
  height: 45px;
  margin: 30px 0;
  font-weight: 500;
  color: #fff;
  border: none;
  background: #190835 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.about_us_content {
  text-align: center;
  color: #28272b;
  font-size: 0.9rem;
}

.about_us_content p {
  text-align: left;
  color: #28272b;
  text-transform: capitalize;
  opacity: 1;
  font-size: 16px;
}

.about_us_content img {
  width: 140px;
  height: 140px;
  object-fit: cover;
}

.listing-barseller {
  background: #f4f5fc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.listing-barseller p {
  font: normal normal bold 18px/27px Roboto;
  letter-spacing: 0px;
  color: #151415;
  opacity: 1;
  margin: 0;
}

.listing-barseller button {
  background: #190835 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #1908353d;
  border-radius: 8px;
  opacity: 1;
  border: none;
  padding: 12px 28px;
}

.about-login {
  justify-content: center !important;
  padding: 0 !important;
  position: relative;
  top: -16px;
}

.about-login span {
  font-size: 10px;
}

/* faq section  start */
.faq_box_section {
  margin: 40px 0 50px;
}

.faq_box_section h3 {
  color: #393939;
}

.faq_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 130px;
  background: #ffffff;
  background: #ffffff;
  font-weight: 500;
  color: #8b8b8b;
  transition: 0.9s ease;
  box-shadow: 0px 2px 7px #00000017;
  border-radius: 9px;
}

.faq_box:hover {
  color: #393939;
  transition: 0.9s ease;
  border: 1px solid #001878;
}

.faq_sub_text {
  color: var(--light-text);
  width: 70%;
  margin: 10px auto 20px;
  text-align: center;
}

.custom_accordion {
  position: relative;
  display: flex;
  text-align: center;
  width: 60%;
  margin: 10px auto;
  align-items: center;
}

.accordion_dot {
  /* position: absolute; */
  /* content: " "; */
  box-sizing: border-box;
  padding: 6px;
  margin-right: 20px;
  border-radius: 50px;
  background: #393939;
}

.accrodion_title2 {
  font-size: 1.1rem;
  color: #393939;

  font-weight: 600;
}

.bottom_border {
  /* position: ; */
  content: " ";
  position: absolute;
  top: 35px;
  width: 100%;
  border: 0.9px solid var(--light-text);
}

.accordion_text5 {
  color: #001978;
  margin: auto;
  font-size: 0.9rem;
  text-align: left !important;
  width: 60% !important;
}

/* faq section  end */

/*********** servicess styling Start  **************/
.services_section {
  padding: 0 110px;
}

.services_section h1 {
  margin-bottom: 7px;
  font-weight: 600;
  font-size: 2.7rem;
  letter-spacing: 0.28px;
  font-weight: 300 !important;
  font-family: Quart, sans-serif;
}

.search_services .search-icon {
  left: 7px;
  top: 14px;
}

.search_services {
  /* width: 1056px; */
  /* padding: 30px; */
  box-sizing: border-box;
  height: 168px;
  /* display: flex; */
  align-items: center;
  text-align: left !important;
  background: #fff;
  background: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}

.search_services input {
  padding-left: 35px;
  background: #f4f4f4 !important;
  max-width: 50%;
  border-radius: 5px !important;
  height: 56px;
}

.search_input_box .input-group {
  align-items: center;
}

.search_input_box {
  margin: 35px 30px;
  /* justify-content: left; */
  align-items: center;
  /* display: flex; */
}

.contact_submit2 {
  margin-left: 20px !important;
  margin-top: 0;
  width: 181px;
  margin-bottom: 0;
  border-radius: 8px !important;
}

.services_card_container {
  padding: 30px;
  box-sizing: border-box;
  height: auto;
  margin: 20px 0;
  /* margin: 0 120px !important; */
  /* display: flex; */
  align-items: center;
  text-align: left !important;
  background: #fff;
  background: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}

.services_card {
  height: 300px;
  display: flex;
  margin-bottom: 22px;
  flex-direction: column;
  padding: 20px;
  position: relative;
  justify-content: space-around;
  transition: 2s ease;
  align-items: center;
  border: 1px solid #70707033;
  border-radius: 9px;
}

.services_card:hover .services_card_hover {
  display: flex;
  transition: 2s ease;
}

.services_card_hover {
  position: absolute;
  width: 100%;
  height: 300px;
  display: none;
  transition: 2s ease;
  /* display: flex; */
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  background: #00000080;
}

.book_btn {
  border: none;
  background: #fff;
  height: 50px;
  color: #001978;
  font-weight: 500;
  box-shadow: 0px 3px 13px #00000045;
  border-radius: 10px;
  width: 165px;
}

.service_card_title {
  color: #131212;
  text-align: center;
  font-size: 14px;
  font-size: 1.1rem;
}

.services_sub_text {
  font-size: 0.9rem;
  color: #131212;
  opacity: 0.67;
  padding: 0 32px;
}

.bottom_cards {
  border-top: 1px solid #cccccc4a;
  padding-top: 18px;
}

.services_price_text {
  color: #001978;
  /* padding-top: 50px; */
  font-size: 1rem;
  font-weight: 500;
}

.removals_container {
  padding: 20px;
}

.removals_modal .search-icon img {
  margin-left: 11px;
}

.removals_container input.inputField {
  background: #f4f4f4;
}

.parking_text {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #1c0434;
}

.removals_container .style_btn,
.style_btn2 {
  /* padding: 12px 35px; */
  /* margin-top: 20px; */
}
.appointment_right_wrapper .style_btn_div323 .style_btn2 {
  padding: none !important;
}

.removals_container .contact_submit {
  margin-bottom: 0;
}

.removals_modal .modal-content {
  border-radius: 10px;
}

.quote_title {
  font-size: 1.3rem;
  margin: 0;
  font-weight: 600;
}

.removals_modal hr {
  border: 0.5px solid rgb(208, 208, 208) !important;
}

/* servicess styling End  */

/*

.stepper_wrapper .StepButton-d2-0-2-8.completed,
.stepper_wrapper .StepButton-d6-0-2-18.completed {
  background: #001978;
}

.stepper_wrapper .StepButton-d2-0-2-8.active,
.stepper_wrapper .StepButton-d6-0-2-18.active {
  background: transparent;
}

.stepper_wrapper .Connector-d3-0-2-25 {
  border: 1px solid #;
}

.stepper_wrapper .StepButtonContent-d3-0-2-9.active,
.stepper_wrapper .StepButtonContent-d7-0-2-19.active {
  color: #001978 !important;
} */

.login_info {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  position: relative;
}
.login_info .btn2 {
  position: absolute;
  right: 0;
}

section.banner-search {
  padding: 55px 0;
}

form.search-input input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 30px #0000001a;
  border-radius: 104px;
  opacity: 1;
  color: #6b6b6b;
  /* font: normal normal 17px/34px "Roboto", sans-serif; */
  padding: 22px 210px 22px 80px;
  border: none;
}

form.search-input {
  width: 66%;
  margin: 0px auto;
}

form.search-input input:focus {
  box-shadow: unset !important;
  box-shadow: 0px 2px 30px #0000001a !important;
}

form.search-input input::placeholder {
  opacity: 0.7;
}

.btnsgorup {
  position: absolute;
  right: 9px;
  top: 7px;
  display: flex;
  gap: 10px;
}

button.btn-buy {
  background: #1c0434 0% 0% no-repeat padding-box;
  border: none;
  font: normal normal bold 18px/22px "Roboto", sans-serif;
  border-radius: 32px;
  opacity: 1;
  padding: 14px 36px;
}

button.btn-sell {
  background: transparent;
  /* border: 1px solid #001978; */
  border: 1px solid #1c0434;
  font: normal normal bold 18px/22px "Roboto", sans-serif;
  border-radius: 32px;
  opacity: 1;
  padding: 14px 36px;
  color: #111;
}

.searchicon {
  position: absolute;
  left: 30px;
  top: 19px;
}

/* inbox */
section.inbox-dashboard {
  padding: 80px 0 80px;
}

.user-dropdown button.btn.btn-secondary {
  padding: 0;
  border-radius: 50px;
  width: 50px;
}

.user-dropdown .dropdown-menu.show {
  left: -111px !important;
}

.common-head h2 {
  text-align: left;
  color: #393939;
  letter-spacing: 0.28px;
  font-weight: 400;
  font-family: Quart, sans-serif;
  opacity: 1;
  font-size: 35px;
}

.inbox-board-main {
  background: #fdfdfd 0% 0% no-repeat padding-box;
  box-shadow: 5px 7px 24px #52505e17;
  border-radius: 9px;
  opacity: 1;
  margin-top: 20px;
  display: flex;
}

.inbox-board-sidebar {
  width: 30%;
  background-color: #fff;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.inbox-board-chatscreen {
  width: 70%;
  padding: 23px;
  height: 100%;
  min-height: 680px;
}

form.search-inputleft-side input {
  background: #f9fafc 0% 0% no-repeat padding-box;
  border: 1px solid #dbe5ed;
  border-radius: 8px;
  padding: 11px;
}

form.search-inputleft-side input:focus {
  box-shadow: unset;
}

form.search-inputleft-side input::placeholder {
  opacity: 0.8;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  right: 10px;
  /* top: 0px; */
}

div.tab-frame input {
  display: none;
}

div.tab-frame label {
  display: block;
  float: left;
  padding: 8px 20px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #111;
  opacity: 0.8;
}

div.tab-frame input:checked + label {
  border-bottom: 3px solid #8472fc;
  color: #8472fc;
  cursor: default;
  opacity: 1;
  font-weight: 600;
}

div.tab-frame div.tab {
  display: none;
  padding: 5px 10px;
  clear: left;
}

div.tab-frame input:nth-of-type(1):checked ~ .tab:nth-of-type(1),
div.tab-frame input:nth-of-type(2):checked ~ .tab:nth-of-type(2),
div.tab-frame input:nth-of-type(3):checked ~ .tab:nth-of-type(3),
div.tab-frame input:nth-of-type(4):checked ~ .tab:nth-of-type(4),
div.tab-frame input:nth-of-type(5):checked ~ .tab:nth-of-type(5) {
  display: block;
}

.tabing-clear {
  border-bottom: 1px solid #ccc;
  opacity: 0.7;
  padding-bottom: 0;
  margin: 0px 20px 20px 20px;
}

.recent-online-card {
  display: flex;
  align-items: center;
  padding: 13px 24px !important;
  justify-content: space-between;
}

.recent-online-card figure {
  margin: 0;
}

.figure-part {
  display: flex;
  align-items: center;
}

.dot-time p {
  letter-spacing: 0px;
  color: #b6b9c3;
  font-size: 11px;
  margin: 0;
}

h4.user-name {
  font-size: 14px;
  letter-spacing: 0px;
  color: #495969;
  margin: 0px !important;
}

span.user-tagline {
  letter-spacing: 0px;
  color: #9095a4;
  font-size: 14px;
  line-height: 1.3;
  display: block;
}

.green-dot,
.ofline-dot {
  content: "";
  background: #04e013;
  height: 9px;
  width: 9px;
  position: absolute;
  border-radius: 50px;
  right: 0;
  bottom: 5px;
}

.ofline-dot {
  background: #ccc !important;
}

a.m_personn {
  text-decoration: none;
}

.recent-online a {
  display: block;
}

.recent-online a:hover {
  background-color: #f0f0fb;
}

.recent-online a.message-acitve {
  background-color: #f0f0fb;
  border-left: 3px solid #8472fc;
}

form.search-inputleft-side {
  margin: 20px;
}

.ofline-timedot {
  height: 7px;
  width: 7px;
  border-radius: 50px;
  right: 0;
  bottom: 5px;
  background: #ccc;
  margin: 0px auto;
  margin-bottom: 10px !important;
}

.pink-dot {
  height: 10px;
  width: 10px;
  content: "";
  background: #e15ac0;
  position: absolute;
  top: 31px;
  left: -4px;
  border-radius: 50px;
  box-shadow: 0px 0px 11px #e15ac0;
}

.message-show {
  background: #8472fc;
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  position: absolute;
  top: -6px;
  left: -3px;
}

figure.avatar {
  margin-bottom: 0;
}

.chatscreen-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.callvedio-icons a {
  background: #e1e5f5;
  height: 40px;
  width: 40px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #001978;
}

.callvedio-icons {
  display: flex;
  gap: 10px;
}

.chatscreen-header {
  border-bottom: 2px solid #f7f7f7bd;
  padding-bottom: 10px;
}

p.box-message-timing {
  color: #9095a4;
  font-size: 11px;
  text-align: right;
  margin-bottom: 7px;
}

.message-bg {
  background: #2d1159;
  padding: 20px;
  border-radius: 11px;
  width: 64%;
  float: right;
}

.the-massage-bg p {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}

.offers-send {
  background: #fff;
  padding: 16px;
  border-radius: 7px;
}

.offers-send-div {
  display: flex;
}

.property-img-cont h3 {
  font-size: 18px;
}

.property-img-cont p {
  margin: 0;
  letter-spacing: 0px;
  color: #b5b5b5;
  font-size: 14px;
}

.property-img-cont p span {
  color: #05d48b;
  font-size: 20px;
  font-weight: 500;
}

.property-img-cont {
  padding: 6px 0;
}

button.btn-blue {
  box-shadow: 0px 3px 13px #0019782e;
  border-radius: 6px;
  opacity: 1;
  display: block;
  width: 100%;
  background: #3399ff;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-body::-webkit-scrollbar {
  display: none;
}
.chat-body {
  height: 100%;
  min-height: 500px;
  max-height: 500px;
  overflow-y: scroll;
  padding: 20px;
}

.chat-body::-webkit-scrollbar {
  width: 5px;
}

.chat-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-body::-webkit-scrollbar-thumb {
  background: #3399ff;
}

.chat-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.message-footer {
  display: flex;
  gap: 20px;
  padding-top: 20px;
  border-top: 2px solid #f7f7f7bd;
}

.btn-crete-offer {
  width: 20%;
}

.input-form-main {
  width: 80%;
}

form.typing-message input {
  background: #f6f6f6;
  padding: 16px 114px 16px 16px;
  border-radius: 7px;
  border: none;
}

form.typing-message input:focus {
  box-shadow: unset;
  background: #f6f6f6;
}

button.create-oofer {
  background: #190835;
  border: none;
  padding: 16px 17px;
  width: 100%;
  border-radius: 14px;
}

a.with-bg {
  background: #190835;
  float: left;
  padding: 10px 14px;
  border-radius: 9px;
  margin-right: -30px;
}

form.typing-message a {
  color: #190835;
}

.icon-attached {
  position: absolute;
  right: 10px;
  display: flex;
  top: 6px;
  align-items: center;
  gap: 22px;
}

form.typing-message div {
  margin-bottom: 0 !important;
}

div#popover-basic {
  border: none;
  box-shadow: 0px 6px 21px #00000029;
  max-width: 390px;
  z-index: 3;
}

div#popover-basic h3 {
  color: #151415;
  font-size: 22px;
  line-height: 27px;
}

div#popover-basic p {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.16px;
  color: #767676;
}

a.quick-response {
  letter-spacing: 0px;
  color: #2d1159;
  font-size: 22px;
  text-align: center;
  display: block;
  font-weight: 700;
}

.my-rely-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.left-reply-offer .message-bg {
  float: left;
  width: 60%;
}

.message-reply p.box-message-timing {
  text-align: left;
}

.two-btn-oofer button {
  width: 49%;
  padding: 10px 0;
}

button.reject-bnt {
  border: 0.5px solid #b5b5b5;
  border-radius: 6px;
  opacity: 1;
  background: transparent;
  color: #111;
  opacity: 0.6;
}

button.accept-btn {
  margin-left: 7px;
  background: #2d1159;
  border: none;
  border-radius: 6px;
}

.two-btn-oofer button:focus {
  box-shadow: unset;
}

/*  */

/* faq */
section.Faq-center {
  padding: 180px 0 80px;
}

.faq-search-cont {
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
}

.faq-search-cont h1 {
  letter-spacing: 0.28px;
  font-weight: 400;
  font-size: 35px;
  font-family: Quart, sans-serif;
}

.faq-search-cont input {
  box-shadow: 0px 2px 30px #0000001a;
  border-radius: 104px;
  border: none;
  padding: 28px;
  padding: 28px 78px 28px 88px;
  background-color: #fff;
}

.faq-search-cont input:focus {
  background-color: #fff;
  box-shadow: unset;
  box-shadow: 0px 2px 30px #0000001a;
}

.newsearch-icon {
  position: absolute;
  left: 12px;
  top: 10px;
  background: #001878;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-mike {
  position: absolute;
  right: 24px;
  top: 26px;
}

.or-text {
  letter-spacing: 0.16px;
  color: #393939;
  opacity: 0.4;
}

div.faq-frame label {
  box-shadow: 0px 2px 7px #00000017;
  border-radius: 9px;
  width: 22.33%;
  margin: 10px;
  text-align: center;
  padding: 34px 0;
  letter-spacing: 0.16px;
  color: #393939 !important;
  opacity: 0.72;
  font-size: 14px !important;
  font-weight: 600;
}

div.faq-frame input:checked + label {
  border: 2px solid #001878;
  color: #001878 !important;
}

.FAQ-treand {
  padding: 0 170px !important;
}

.coomn-bg {
  background: #ccc;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 8px;
  border-radius: 10px;
}

.coomn-bg svg path {
  fill: #fff;
}

div.faq-frame input:checked + label .coomn-bg {
  background: #001878;
}

.faq-heading {
  padding: 60px 170px;
}

.faq-heading h3 {
  letter-spacing: 0.31px;
  color: #393939;
  opacity: 1;
  font-weight: 700;
}

.faq-heading p {
  letter-spacing: 0.16px;
  color: #393939;
  opacity: 0.4;
  margin: 0;
}

.blue-dot {
  background: #111;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  position: absolute;
  left: 10px;
}

.accordion-header .accordion-button:not(.collapsed) {
  background: transparent;
  padding: 20px 32px;
}

.accordion-header .accordion-button {
  padding: 20px 32px;
}

.accordion-main .accordion-item {
  border: none;
  border-bottom: 1px solid #ccc;
}

.accordion-button:focus {
  box-shadow: unset !important;
}

section.Appointcenter-center {
  padding: 180px 0 80px;
}

div.appointment-frame input:checked + label {
  border: none;
  background: #1c0434;
  border-radius: 9px;
  color: #fff;
}

div.appointment-frame label {
  border-radius: 9px;
  background: #f8f8f8;
}

.appointment-frame .clearfix {
  background: #f8f8f8;
  width: 326px;
  border-radius: 9px;
  padding: 5px;
}

a.login-sign:hover {
  background: none !important;
}

/*  */

.email-svg {
  position: absolute;
  top: 11px;
  left: 14px;
  z-index: 567;
}

.eye-svg {
  position: absolute;
  right: 14px;
  top: 11px;
  z-index: 3432;
}

button.linked-in {
  background: transparent linear-gradient(180deg, #098dd5 0%, #0684ca 100%) 0%
    0% no-repeat padding-box !important;
}

.dashboard-edit {
  background: #001978;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  border: 1px solid #fff;
  position: absolute;
  right: 11px;
  top: 9px;
}

.avatar-profile figure {
  height: 160px;
  width: 120px;
}

.avatar-profile figure {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  margin: 0px auto;
  border: 1px solid #2d1159;
}

figure.avatar img {
  width: 100%;
  object-fit: cover;
}

.profile-edit {
  border-radius: 50%;
  right: 0;
  background: #001978;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  position: absolute;
  bottom: 0;
}

.edit-profile-avtar-div h3 {
  letter-spacing: 0px;
  color: #151415;
  opacity: 1;
  font-weight: 700;
}

form.edit-profile-form input,
form.edit-profile-form select {
  background: #f4f4f4;
  padding: 15px 23px;
  border: none;
  border-radius: 8px;
}

form.edit-profile-form input:focus,
form.edit-profile-form select:focus {
  box-shadow: unset;
  background: #f4f4f4;
}

.down-arrow {
  position: absolute;
  right: 8px;
  top: 52px;
}

form.edit-profile-form label {
  letter-spacing: 0.14px;
  color: #190835;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

button.update-profile {
  background: #190835;
  border-radius: 8px;
  padding: 15px 80px;
  margin: 0px auto;
  display: block;
  margin-top: 50px;
}

form.faq-search input {
  border: 2px solid #bcbcbc30;
  border-radius: 9px;
  opacity: 1;
  padding: 16px 46px;
}

.search-col {
  position: absolute;
  top: 17px;
  left: 13px;
}

form.faq-search input:focus {
  box-shadow: unset;
}

.help-faq .accordion-item {
  border: none;
  margin-bottom: 10px;
}

.help-faq .accordion-button,
.help-faq .accordion-button:not(.collapsed) {
  background: #f2f2f2;
  border-radius: 14px !important;
  opacity: 1;
  color: #322f2f;
  opacity: 1;
  font-weight: 600;
  font-family: "Roboto";
}

.help-faq .accordion-body {
  background: #f9f9f9;
  margin: 21px;
  border-radius: 12px;
}

section.propety-details {
  padding: 80px 0;
}

.go-back-div p {
  display: flex;
  align-items: center;
  gap: 10px;
  letter-spacing: 0.04px;
  color: #000000;
  opacity: 1;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.near-properties .slick-list {
  height: 470px;
}

.property-details--head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li.first-grey .div-overlay-grey {
  top: 0;
  border-radius: 5px;
  background: rgba(1, 1, 1, 0.4);
}

li.first-grey .playicon {
  height: 40px;
  width: 40px;
}

.view-div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.view-div p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #5d5d5d;
}

.property-details--head h3 {
  font-weight: 700;
  font-size: 30px;
  color: #393939;
}

.propety-details-box {
  box-shadow: 0px 7px 17px #00000014;
  border-radius: 10px;
  opacity: 1;
  padding: 30px;
  background: #fff;
  margin-top: 17px;
}
.propety-details-box22 {
  box-shadow: none !important;
}

.img-list ul {
  padding: 0;
  display: flex;
  gap: 10px;
}

/* .img-main img {
  width: 100%;
} */

.img-list ul li img {
  height: 65px;
  object-fit: cover;
}

.box-one-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.three-iocn {
  display: flex;
  gap: 20px;
}

.heart-col {
  height: 30px;
  width: 30px;
  box-shadow: 0px 5px 11px #0000001a;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-col {
  height: 30px;
  width: 30px;
  background: #3399ff;
  box-shadow: 0px 5px 11px #0000001a;
  border-radius: 8px;
  opacity: 1;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.propety-box-one,
.propety-box-two {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
}

h3.green-big {
  letter-spacing: 0.01px;
  color: #05d48b;
  opacity: 1;
  font-size: 36px;
  margin: 0;
}

.flat-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.flat-details p span {
  font-size: 14px;
  font-weight: 500;
}

.flat-details p {
  font-size: 11px;
  letter-spacing: 0px;
  color: #231f1f;
  margin: 0;
}

.flat-details h4 {
  letter-spacing: 0px;
  color: #231f1f;
  opacity: 1;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

.location-icon p {
  letter-spacing: 0px;
  color: #5d5d5d;
  opacity: 1;
  display: flex;
  align-items: center;
  gap: 4px;
}

.facilit-listicon {
  height: 38px;
  width: 38px;
  background: #e7e7e7;
  border-radius: 50px;
  text-align: center;
  padding: 5px 0;
}

.facilit-list ul {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.facilit-list-one p {
  margin: 0;
}

.facilit-list-one {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.facilit-list ul li {
  flex: 1 0 174px;
}

button.edit-details,
button.view-more {
  background: #190835;
  border: none;
  border-radius: 8px;
  padding: 10px 38px;
}

button.view-more {
  background: transparent !important;
  border: 1px solid #190835;
  color: #001978;
}

.details-teo-btn {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 20px;
}

button.view-more {
  border: 0.5px solid #b5b5b5;
  border-radius: 8px;
  color: #707070 !important;
}
button.view-more:hover {
  background: #190835 !important;
  color: #fff !important;
}

.img-list ul li img {
  border-radius: 5px;
}

.map-location img {
  height: 460px;
  width: 100%;
  object-fit: cover;
}

.map-location h5,
.near-public-facilty h5,
.about-location h5,
.additional-feature h5 {
  letter-spacing: 0px;
  color: #181818;
  opacity: 1;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.near-list-main {
  display: flex;
  gap: 80px;
  padding: 20px 0;
}

.near-list-one {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.near-list-content h6 {
  letter-spacing: 0px;
  color: #231f1f;
  opacity: 1;
  font-size: 16px;
}

.near-list-content p {
  margin: 0;
  letter-spacing: 0px;
  color: #7d7f88;
  opacity: 1;
  font-size: 14px;
}

.about-location {
  padding: 20px 0;
}

.about-location p {
  margin: 0;
  letter-spacing: 0px;
  color: #7d7f88;
  opacity: 1;
  font-size: 15px;
}

.style-description {
  width: 100%;
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
}

.style-description p {
  letter-spacing: 0.11px;
  color: #7c7c7c;
  opacity: 0.97;
  font-size: 16px;
  margin-bottom: 3px;
}

.style-description h6 {
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
}

.additional-feature {
  background-color: #fafafa;
  padding: 18px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.additional-feature .row {
  row-gap: 12px;
}

.style-description h6 a {
  color: #001978;
}

p.card-address {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #5d5d5d;
  opacity: 1;
  display: flex;
  align-items: center;
  gap: 10px;
}

p.properties-prize {
  text-align: left;
  letter-spacing: 0.01px;
  color: #05d48b;
  opacity: 1;
  /* font-size: 25px; */
  font: normal normal 22px/25px "Roboto", sans-serif;
  font-weight: 500;
}

.btn:hover {
  background: #1c0434 !important;
}

button.view-property {
  background: #1c0434 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  font-size: 17px;
  padding: 10px 0;
}

.button.view-property:hover {
  background: #1c0434 !important;
}

.slick-list {
  /* min-width: 320px !important; */
  overflow-x: scroll !important;
}

.cr_t {
  /* box-shadow: 0px 15px 30px #0000001C; */
}

.card-main {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: rgb(33 33 33 / 8%) 0px 15px 30px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */

  border-radius: 20px !important;

  border: none !important;
  margin: 10px;
}

section.near-properties {
  padding: 50px 0 0;
}

.slider-head h3 {
  text-align: left;
  font: normal normal bold 22px/30px "Roboto" !important;
  letter-spacing: 0.2px;
  color: #190735;
  -webkit-font: normal normal bold 22px/30px "Roboto";
}

.additional-features-contct .additional_feature2 {
  border: none;
  display: flex;
  padding: 0;
}

.additional-features-contct .additional_feature2 input {
  border-radius: 50px;
  padding: 8px;
}

.additional-features-contct .additional_feature2 .form-check-input:checked {
  background-color: #2d1159;
  border-color: #2d1159;
  box-shadow: unset;
}

.additional-features-contct .additional_feature2 label {
  letter-spacing: 0px;
  color: #322f2f63;
  font-size: 16px;
}

.get-help h3 {
  letter-spacing: 0px;
  color: #151415;
  opacity: 1;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 28px;
}

.slider-head {
  display: flex;
  /* font-weight: bold; */
  /* font-family: "Roboto", 'sans-serif'; */
  /* font: normal normal bold 22px/76px 'Roboto', 'sans-serif'; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 120px;
}

.slider-head h3 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #190735;
}

a.view-all {
  letter-spacing: 0.2px;
  color: #190835;
  opacity: 1;
  margin-right: -20px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  /* font-family: 'Roboto'; */
  padding-top: 6px;
}

img.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section.near-properties .swiper {
  padding: 10px 0 40px;
}

.near-public-facilty {
  padding-top: 20px;
}

a.go-back-at {
  text-decoration: none;
  display: inline-block;
}

section.verify-middle {
  padding: 80px 0;
}

div.verify-frame input:checked + label {
  border-bottom: 3px solid #1c0434;
  color: #1c0434;
  cursor: default;
  opacity: 1;
  font-weight: 700;
}

div.verify-frame label {
  letter-spacing: 0px;
  color: #989696;
  font-size: 24px;
  font-weight: 600;
  padding: 8px 0;
  margin-right: 30px;
  font-weight: 600;
  /* font-family: "Roboto"; */
  /* font-family: "Roboto"; */
}

.vedio-icon {
  background: #0d6efdad;
  width: 28px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vedio-cont {
  display: flex;
  gap: 10px;
}

.vedio-verification {
  padding: 20px;
  /* min-width: 380px;
  margin-left: 10px; */
  border: 1px solid #7070704a;
  border-radius: 11px;
  opacity: 1;
}

.Verify-online .row {
  margin-top: 28px;
}

button.verify-btn {
  box-shadow: 0px 3px 13px #0019783d;
  border-radius: 5px;
  background: #1c0434;
  border: navajowhite;
  padding: 8px 62px;
  display: block;
  margin: 0px auto;
  letter-spacing: 0.15px;
  color: #ffffff;
  font-size: 14px;
}

.vedio-cont-head h6 {
  font-size: 16px;
  letter-spacing: 0.01px;
  color: #28272b;
  font-weight: 700;
}

.vedio-cont-head p {
  letter-spacing: 0px;
  color: #28272b;
  font-size: 16px;
  line-height: 24px;
}

.exclamation-new,
.right-check {
  position: absolute;
  right: 14px;
  top: 10px;
}

.check-bg input {
  display: block !important;
}

.check-bg {
  background: #f8f8f8;
  border-radius: 9px;
  margin: 10px 0;
  height: 58px;
  padding: 10px 20px;
  width: 50%;
}

.check-bg .form-check-input:checked {
  background-color: #001978;
}

.check-bg label {
  letter-spacing: 0px;
  color: #151515 !important;
  font-size: 16px !important;
  opacity: 1;
  margin: 0 !important;
}

.availability-div input {
  display: block !important;
}

.availability-div input {
  display: block !important;
  background: #f8f8f8;
  padding: 19px;
  border-radius: 9px;
  border: none;
}

.availability-div {
  width: 50%;
}
.availability-div h5 {
  font: normal normal bold 18px/26px Roboto;
  letter-spacing: 0px;
}
.availability-div label {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #151515 !important;
  padding: 10px 0 0 !important;
}

.form-div-main {
  padding: 20px 0px 20px;
  border-bottom: 1px solid #ccc;
}
.form-div-main h6 {
  font-weight: 700;
}
p.please-selct {
  padding: 0px 34px;
  margin: 0;
  position: relative;
  top: -15px;
  z-index: 888;
  font-size: 14px;
}

.preference-acco .accordion-button {
  letter-spacing: 0px;
  color: #28272b;
  font-weight: 700;
  font-size: 16px;
}

.bio-content {
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  opacity: 1;
  padding: 10px 18px;
}

.bio-content p,
.vedio-modal p {
  margin: 0;
  letter-spacing: 0px;
  color: #393939;
  opacity: 1;
  font-size: 15px;
  line-height: 25px;
}

.vedio-modal p {
  padding: 4px 10px;
}

.vedio-img-div {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  min-height: 240px;
  max-height: 240px;
}

.vedio-img-div img {
  width: 100%;
  height: 247px;
  object-fit: cover;
  /* min-height: 240px;
  max-height: 240px; */
  border-radius: 12px;
}

.modal-vedio-col .modal-content,
.name-address-modal .modal-content {
  border-radius: 20px;
  width: 419px;
}

.vedio-modal h4 {
  letter-spacing: 0.23px;
  color: #190835;
  opacity: 1;
  font-weight: 700;
}

.bio-content p {
  font-weight: 600;
  line-height: 24px;
}

.buttons-up-record button {
  width: 100%;
}

.buttons-up-record {
  display: flex;
  gap: 12px;
  padding: 60px 0 9px;
}

button.upload-btn,
button.recode-btn {
  border: 1px solid #190835;
  border-radius: 8px;
  opacity: 1;
  background: transparent;
  color: #190835;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 12px;
}

button.recode-btn {
  background-color: #190835 !important;
  color: #fff;
}

button.upload-btn:hover {
  background-color: #190835;
  color: #fff;
}

button.upload-btn:hover svg path {
  fill: #fff;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  height: 50px;
}

.upload-btn-wrapper input[type="file"] {
  left: 0;
  top: 0;
  opacity: 0;
  position: absolute;
  height: 50px;
  width: 100%;
  cursor: pointer;
}

.ulpoading-cont {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

.ulpoading-cont p {
  margin: 0;
  letter-spacing: 0.28px;
  color: #190735;
  opacity: 1;
  font-size: 14px;
  padding: 0;
}

p.comptletion {
  color: #3399ff;
}

.ulpoading-vedio-loader .progress {
  height: 8px;
}

.ulpoading-vedio-loader .progress-bar {
  background-color: #3399ff !important;
}

.ulpoading-vedio-loader {
  padding: 12px 0;
}

h5.security-msg {
  letter-spacing: 0.36px;
  color: #190735;
  opacity: 0.74;
  font-size: 15px;
  font-weight: 700;
}

.verification-vedio p {
  padding: 15px 65px;
  margin: 0;
}

.evidence-proof {
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  opacity: 1;
  padding: 25px 30px;
  text-align: left;
}

.evidence-proof ul li {
  list-style-type: disc;
}

.evidence-proof h6 {
  letter-spacing: 0px;
  color: #001978;
  opacity: 1;
  font-size: 20px;
}

.evidence-proof p {
  padding: 0;
  letter-spacing: 0px;
  color: #393939;
  opacity: 0.5;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
}

.evidence-proof .upload-btn-wrapper button {
  border: 2px solid #ccc;
  width: 50%;
  border-radius: 10px;
  height: 48px;
}

.evidence-proof .upload-btn-wrapper input[type="file"] {
  width: 50%;
}

.evidence-proof .upload-btn-wrapper button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.adress-modal-main {
  padding: 19px 39px;
}

.plans {
  display: flex;
  justify-content: flex-start;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
  width: 21%;

  padding: 0;
}

.plans .plan .plan-content {
  text-align: center;
  padding: 30px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #70707033;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  height: 72px;
}

.plans .plan .plan-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  /* font: normal normal medium 16px/21px "Roboto"; */
  line-height: 24px;
  /* font-weight: 500; */
  color: #252f42;
}

.legal-col {
  padding: 29px 0 0;
}

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked + .plan-content {
  background: #eaf1fe;
}

.legal-col h4 {
  padding-bottom: 20px;
}

span.sold-badge {
  display: flex;
  position: absolute;
  z-index: 1;
  right: 0;
  background: #955b5c;
  padding: 11px 13px;
  top: 10px;
  align-items: center;
  gap: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

span.sold-badge p {
  margin: 0;
  color: #fff;
}

.solicitor-card {
  width: 25%;
  margin: 0px auto;
  box-shadow: 0px 5px 17px #0000001f;
  border-radius: 16px;
  opacity: 1;
}

.card-content {
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-content p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 4px;
}

p.col-green {
  color: #05d48b;
  font-weight: 700;
  font-size: 20px;
}

p.startig-col {
  font-weight: 600;
}

button.hire-now {
  border: none;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  color: #fff;
  background: #190835;
  padding: 12px;
}

.card-content h5 {
  margin: 0;
}

.all-cover {
  padding: 10px;
}

section.Solicitoremid {
  padding: 100px 0;
}

.Solicitoremid-head h1 {
  letter-spacing: 0.48px;
  color: #393939;
  opacity: 1;
  font-weight: 700;
}

.Solicitoremid-head p {
  letter-spacing: 0px;
  color: #151415;
  opacity: 1;
  font-size: 22px;
  font-weight: 600;
}

.card-img img {
  border-radius: 16px;
  width: 100%;
}

.Solicitoremid-head {
  margin-bottom: 50px;
}

section.Memorandummid {
  padding: 100px 0;
}

.agree-priced {
  background: #2d1159;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}

.agree-priced p {
  margin: 0;
  color: #ffffff;
  opacity: 0.8;
  font-size: 26px;
}

.agree-priced p:last-child {
  font-size: 47px;
  color: #fff !important;
  opacity: 1;
}

.memorandum-main {
  box-shadow: 0px 6px 12px #00000014;
  padding: 40px;
  width: 50%;
  margin: 0px auto;
}

h5.heading-memo {
  letter-spacing: 0px;
  color: #151415;
  font-size: 26px;
  font-weight: 700;
}

.vendor-info {
  border: 1px solid #d2d2d2;
  border-radius: 7px;
  opacity: 1;
  padding: 30px;
}

form.vedor-form input,
form.vedor-form select {
  background: #f4f4f4;
  border: none;
  padding: 10px 18px;
}

form.vedor-form label {
  letter-spacing: 0.14px;
  color: #505050;
  opacity: 1;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
}

.vendor-info h5 {
  text-align: center;
  margin-bottom: 27px;
  font-family: "Roboto";
}

.lebeyesno {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  max-width: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lebeyesno input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.lebeyesno {
  cursor: pointer;
  width: 48.5%;
}

.lebeyesno .plan-yesno {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.lebeyesno input[type="radio"]:checked + .plan-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #216fe0;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
  box-shadow: 0px 0px 0px 2px #0066ff;
}

.lebeyesno input[type="radio"]:checked + .plan-yesno {
  background: #001978;
  color: #fff;
}

.lebelyeno-col {
  display: flex;
  justify-content: flex-start;
  gap: 73px;
}

.plan-yesno {
  padding: 10px 20px;
  background: #f4f4f4;
  border-radius: 8px;
}

.tabbo-form {
  gap: 118px;
}

button.sbmit-btn {
  border: none;
  width: 100%;
  border-radius: 8px;
  color: #fff;
  background: #190835;
  padding: 10px;
  margin: 0px auto;
  max-width: 60%;
  display: block;
  margin-top: 10px;
}

.solicitor-details {
  width: 50%;
  margin: 0px auto;
}

.mid-code {
  padding: 80px 0;
}

.back-btn a {
  display: flex;
  align-items: center;
  gap: 4px;
}

.back-btn a {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #111;
  text-decoration: none;
}

.details-solicotr-box {
  box-shadow: 0px 1px 12px #00000014;
  border-radius: 7px;
  padding: 45px;
}

.wrap-box {
  width: 67.9%;
  margin: 0px auto;
}

section.Floorplanmid-main {
  padding: 80px 0;
}

.Floorplanmid-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.floorplan-box {
  box-shadow: 5px 7px 24px #52505e17;
  border-radius: 9px;
  padding: 36px 60px;
  margin-top: 25px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #c0c0c0;
  border-bottom: 2px solid #c0c0c0;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
  height: 100%;
  width: 100%;
  text-indent: -9999px;
  border-style: dashed;
  border-radius: 10px;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.floor-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px 0;
}

.floor-img-upload {
  width: 30%;
  height: 140px;
}

input.custom-file-input {
  width: 100%;
  height: -webkit-fill-available;
  cursor: pointer;
}

.imges-iocn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.create-floor-plan {
  position: absolute;
  top: 48px;
  left: 38px;
}

.floor-img-uploadwo .custom-file-input::before {
  border-color: #001978;
}

.floor-img-uploadwo h6 {
  color: #001978;
}

.floor-plan-btns {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.floor-plan-btns button {
  width: fit-content;
  padding: 15px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.18px;
}

button.back-btn {
  background: transparent;
  color: #393939;
  /* border: 1.5px solid #b5b5b5;
  box-shadow: 0px 3px 13px #0019781f; */
  border: 1.5px solid #b5b5b5;
}

button.back-btn:hover {
  border-color: #1c0434;
}

button.Continue-btn {
  border: 1.5px solid;
  background: #1c0434;
  border-color: #1c0434;
  box-shadow: 0px 3px 13px #00197821;
}

button.direct-floor {
  box-shadow: 0px 3px 13px #3399ff3d;
  border-radius: 10px;
  background: #3399ff;
  padding: 10px 14px;
}

section.payment-method {
  padding: 80px 0;
}

h2.heading-select {
  text-align: center;
  letter-spacing: 0.28px;
  color: #393939;
  font-size: 28px;
  font-weight: 700;
}

.payment-box {
  box-shadow: 5px 7px 24px #52505e17;
  border-radius: 9px;
  padding: 40px 50px;
  margin-top: 34px;
}

span.small-info {
  width: 100%;
  float: left;
  margin-bottom: 12px;
  letter-spacing: 0px;
  color: #9d9c9c;
  font-size: 13px;
}

.payment-wrap-box {
  width: 50%;
  margin: 0px auto;
}

.payment-box label {
  letter-spacing: 0px;
  color: #151415;
  font-weight: 700;
  margin: 0;
}

.payment-box input {
  border: 0.5px solid #ebebeb;
  border-radius: 9px;
  height: 56px;
  padding: 0px 50px 0px 50px;
}

.payment-box input::placeholder {
  color: #bbc1d3;
  font-size: 13px;
}

.payment-box input:focus {
  box-shadow: unset;
}

img.my-mastro {
  width: 30px;
  position: absolute;
  bottom: 12px;
  left: 11px;
}

.verifiedsccol {
  position: absolute;
  right: 13px;
  bottom: 17px;
}

.cvv-number input {
  text-align: center;
}

.cvv-number input::-webkit-outer-spin-button,
.cvv-number input::-webkit-inner-spin-button,
.input-one input::-webkit-outer-spin-button,
.input-one input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dots-icon {
  position: absolute;
  right: 13px;
  bottom: 18px;
}

.cvv-number input::placeholder,
.input-one input::placeholder {
  letter-spacing: 0px;
  color: #001978;
  font-weight: bold;
}

.date-expiry {
  display: flex;
  gap: 20px;
  align-items: center;
}

.date-expiry p {
  margin: 0;
}

.input-one input {
  padding: 0 10px;
  text-align: center;
}

.other-wway-acnhor a {
  padding: 14px 30px;
  border-radius: 9px;
  background: #f7f7f7;
  color: #111;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 10px;
}

.common-width {
  width: 20px;
}

.other-wway-acnhor {
  padding: 14px 0px;
}

.other-way-pay {
  padding-top: 20px;
  margin-top: 28px;
  border-top: 1px solid #cccc;
}

.linked-in img {
  width: 20px;
}

.payment-modal .modal-dialog {
  border: none;
  margin: auto;
  max-width: 400px;
}

.payment-modal .modal-content {
  border-radius: 30px;
}

.payment-modal .modal-header {
  border: none;
}

.payment-modal .modal-content {
  border-radius: 30px;
  background: #1b0333;
  padding: 15px 37px;
}

.payment-modal-body h4,
.payment-modal-body p {
  color: #fff;
}

.payment-modal .modal-content .close_btn {
  position: absolute;
  right: 15px;
  top: 16px;
}

.div-icon-two {
  width: 100px;
  height: 100px;
  margin: 0px auto;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.6);
}

.div-icon-one {
  width: 121px;
  height: 121px;
  margin: 0px auto;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.close-btn {
  position: absolute;
  right: 12px;
  top: 12px;
}

.solictor-frame input:checked + label {
  cursor: default;
  box-shadow: 0px 2px 7px #1907351f;
  border: 0.30000001192092896px solid #001978 !important;
  color: #190735;
  border: none;
}

.solictor-frame label {
  box-shadow: 0px 2px 7px #1907351f !important;
  border: 0.30000001192092896px solid #001978 !important;
  color: #190735 !important;
  width: 40%;
  border-radius: 10px;
  padding: 14px 38px !important;
  font-weight: 700;
}

.solictor-frame .clearfix {
  display: flex;
  gap: 0px;
}

.solictor-frame input {
  position: relative;
  display: block !important;
  left: 28px;
  top: 19px;
}

label.buying-label {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
}

.solictor-pop-buying input {
  position: unset;
  background: #f4f4f4;
  border: none;
  padding: 10px 41px;
  border-radius: 8px;
}

.solictor_modal .modal-body {
  padding: 40px;
}

.eru-icon {
  position: absolute;
  top: 38px;
  left: 11px;
}

label.newselectyes,
label.newselectno {
  padding: 0 !important;
  border: none !important;
  box-shadow: unset !important;
}

.newselect input {
  display: none !important;
}

.newselect input[type="radio"]:checked + .plan-yesno {
  background: #001978;
  color: #fff;
}

.newselect .plan-yesno {
  padding: 16px 23px;
}

.newselect {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

button.btn-sbmit {
  background: #1c0434;
  margin: 20px auto 0;
  display: block;
  width: 170px;
  padding: 12px 10px;
  border-radius: 8px;
}

.search-iconm {
  position: absolute;
  left: 10px;
  top: 7px;
}

section.common-steps-main {
  padding: 80px 0;
}
.left-cont-srep4 {
  font: normal normal 300 20px/46px "Roboto";
  letter-spacing: 0.18px;
  color: #190835;
}
.list-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  letter-spacing: 0.28px;
  font-weight: 400;
  font-family: Quart, sans-serif;
}

.list-main-under {
  box-shadow: 5px 7px 24px #52505e17;
  border-radius: 9px;
  padding: 50px 0px;
}

.list-main-under div#RFS-StepperContainer {
  padding: 0;
}

.form-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

form.confirmation-form {
  padding: 28px 20px;
}

button.btn-submit {
  display: block;
  margin: 30px auto 0;
  width: 335px;
  box-shadow: 0px 3px 13px #00197821;
  border-radius: 10px;
  padding: 11px 0;
  background: #1c0434;
}

form.confirmation-form label {
  letter-spacing: 0.14px;
  color: #190835;
  font-size: 16px;
  font-weight: 700;
}

.offer-coditions label {
  width: 21%;
  text-align: center;
  font-weight: 600 !important;
  font-size: 15px !important;
  letter-spacing: O !important;
}

.offer-coditions .newselect .plan-yesno {
  padding: 16px 12px;
}

form.confirmation-form input {
  border-radius: 8px;
  opacity: 1;
  background: #f4f4f4;
  border: none;
  padding: 13px 20px;
  border: 1px solid #fff;
}

form.confirmation-form input:focus {
  box-shadow: unset;
  border: 1px solid #ccc;
}

.list-heading h2 {
  letter-spacing: 0.28px;
  color: #393939;
  font-size: 28px;
}

.list-heading a {
  letter-spacing: 0.2px;
  color: #6c6c6c;
  opacity: 1;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.form-heading a {
  letter-spacing: 0.18px;
  color: #1c0434;
  opacity: 1;
}

.form-headinga {
  padding: 15px 84px;
}
.property-box {
  cursor: pointer;
}
.property-box img {
  border-radius: 15px;
  opacity: 1;
  height: 100%;
  min-height: 266px;
  max-height: 266px;
  object-fit: cover;
}

.overlay-img {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.property-box h5 {
  position: absolute;
  bottom: 30px;
  z-index: 3;
  left: 0;
  right: 0;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.slect-property-type {
  padding: 0 120px;
}

.suggestion-value {
  width: 26%;
  margin: 0px auto;
  padding: 20px 0;
  border-radius: 10px;
  background: transparent
    linear-gradient(111deg, #2f5cfc 0%, #594ff5 56%, #7a45ef 100%) 0% 0%
    no-repeat padding-box;
}

.suggestion-value h6,
.suggestion-value h4 {
  color: #fff;
}

.reals-me-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

a.read-me {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 700;
  display: block;
  text-decoration: none;
}

form.confirmation-form input::-webkit-outer-spin-button,
form.confirmation-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.eru-iocn {
  position: absolute;
  bottom: 41px;
  left: 18px;
}

.eru-iocn p {
  margin: 0;
  letter-spacing: 0px;
  color: #28272b;
  font-size: 23px;
  font-weight: 600;
}

.ero-col input {
  padding-left: 48px !important;
}

.steps-btn button {
  width: 15%;
}

.file {
  position: relative;
  display: flex;
  align-items: center;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  /* font-size: 1rem; */
  /* font-weight: 300; */
  color: #393939;
  /* cursor: pointer; */
  outline: 0;
  /* font: normal normal medium 14px/20px "Roboto"; */
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  /* border: 0.5px solid #b5b5b5; */
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* background-color: hsl(0, 0%, 100%); */
  background-color: #cecece;
  color: hsl(0, 0%, 29%);
  padding: 5px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* .file > label:hover {
  border-color: hsl(0, 0%, 21%);
}

.file > label:active {
  background-color: hsl(0, 0%, 96%);
} */

.file > label > i {
  padding-right: 5px;
}

.form-heading-steo4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 85px;
}

.left-cover-img img {
  height: 317px;
  border-radius: 15px;
  object-fit: cover;
  width: 100%;
}

.left-cover-img {
  height: 317px;
  border-radius: 15px;
}

.cover-upload {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete-col {
  background: #fff;
  border-radius: 50px;
  height: 33px;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-box-img {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

.imgbox {
  height: 147px;
  border: 1px solid #ccc;
  flex: 1 0 195px;
  border-radius: 8px;
  border-style: dashed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploaded-images {
  padding: 0 85px;
}

.epc-main-right {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border-style: dashed;
  border: 1px solid #ccc;
}

.epc-top {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  align-items: center;
  padding: 20px 0;
}
.epc-top p {
  letter-spacing: 0px;
  color: #151415;
  font-size: 22px;
  font-weight: 700;
  font-family: "Roboto";
  margin: 0;
}
.epc-img {
  border: 1px solid #111;
  border-style: dashed;
  border-radius: 3px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.epc-cont h6 {
  margin: 0;
}

h6.col-blue {
  color: #2f388d;
}

.epc-cont h6 {
  margin: 0;
  letter-spacing: 0px;
  color: #151414;
  opacity: 1;
}

.epc-img p {
  margin: 0;
  background: #111;
  color: #fff;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  font-weight: 700;
}

.epic-bottom p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 3px;
}

.epic-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border-top: 1px solid #ececec;
}

p.greencol {
  color: #05d48b !important;
}
button.accordion-button {
  letter-spacing: 0.2px;
  color: #393939 !important;
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto";
}
.accordion-main .accordion-body {
  letter-spacing: 0.16px;
  color: #001978;
  font-size: 16px;
  line-height: 26px;
  font-family: "Roboto";
}
.accordion-main .accordion-button::after {
  content: "+" !important;
  display: flex;
  align-items: center;
  color: #9d9c9c;
  justify-content: center;
  background-image: unset !important;
}
.accordion-button {
  padding-right: 15px !important;
}
.accordion-main .accordion-button:not(.collapsed)::after {
  content: "-" !important;
  display: flex;
  align-items: center;
  font-size: 2.9rem;

  color: #9d9c9c;
  font-weight: 300;
  justify-content: center;
  background-image: unset !important;
}
form.serach-fitler-form {
  display: flex;
  justify-content: flex-start;
  gap: 9px;
  align-items: center;
}
.epc-mainri {
  border: 1px solid #001978;
}
.epc-wrap .floor-upload {
  padding: 0px 0px 50px;
}

.search-here div {
  margin-bottom: 0 !important;
}

.top-filter-main {
  box-shadow: 0px 2px 12px #00000017;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
}

.search-here {
  width: 100%;
}

.search-here input {
  padding: 10px 25px 10px 50px;
  border: none;
}

.search-here input:focus {
  box-shadow: unset;
}

.search-here input::placeholder {
  letter-spacing: 0.07px;
  color: #000000;
  opacity: 1;
  font-size: 17px;
  font-weight: 700;
}

.search-icon-her {
  position: absolute;
  top: 10px;
  left: 17px;
}

.closed-icon {
  position: absolute;
  right: 7px;
  top: 10px;
}

.common-btn-drop button.btn.btn-secondary {
  background-color: #efefef;
  color: #000000;
  border-color: #efefef;
  width: 129px;
  height: 48px;
  letter-spacing: 0.03px;
  color: #000000;
  opacity: 1;
  font-size: 15px;
  font-weight: bold;
}

.common-btn-drop button.btn.btn-secondary:focus {
  box-shadow: unset;
}

.buttonfive-drop button {
  height: 48px;
  background-color: transparent;
  letter-spacing: 0px;
  color: #190735;
  opacity: 1;
  border: 2px solid#190735;
  font-weight: bold;
}

.submenu-down.mb-3 {
  margin: 0px !important;
}

.dropdown-menu.show .dropdown-item.active {
  background-color: transparent !important;
}

ul.submenu-down {
  padding: 0px 10px;
  margin: 0;
}

.downone select:focus {
  box-shadow: unset;
}

.downone select {
  cursor: pointer;
}

ul.submenu-down.downthree p {
  margin: 0;
}

ul.submenu-down.downthree {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.third-menu {
  min-width: 260px;
  left: -132px !important;
}

.fourth-filter.dropdown-menu.show {
  min-width: 600px;
  left: -571px !important;
  padding: 20px;
}

.filter-head h6 {
  letter-spacing: 0.04px;
  color: #000000;
  opacity: 1;
  font-weight: 700;
  font-size: 18px;
}

.filter-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-head h6,
.filter-head p {
  margin: 0;
}

.fourth-filter label {
  letter-spacing: 0.03px;
  color: #000000;
  opacity: 1;
  font-weight: 700;
  font-size: 15px;
}

.fourth-filter select {
  height: 53px;
  border-radius: 10px;
}

.checks-box label {
  font-weight: 500;
}

.thireslector {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.buttonfive-drop button:hover {
  background-color: #0ff;
  border-color: #0ff;
  color: #190735;
}

span.filter-count {
  background: #190735;
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonfour-drop button {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.result-div {
  display: flex;
  align-items: center;
  gap: 11px;
}

.search-reasult-main {
  margin-top: 34px;
}

.search-filter-head h6 {
  letter-spacing: 0.22px;
  color: #6d6d6d;
  font-size: 22px;
  line-height: 26px;
}

.result-div h2 {
  letter-spacing: 0.33px;
  color: #131212;
  font-weight: 700;
  font-size: 33px;
}

.result-div p {
  letter-spacing: 0.06px;
  color: #000000;
  opacity: 0.5;
  font-size: 16px;
}

.property-stuckrigth {
  width: 30%;
  float: left;
}

.property-stuckleft {
  width: 68%;
  float: left;
  margin-right: 10px;
  height: 100%;
  min-height: 290px;
  max-height: 290px;
}

.property-stuckleft img {
  width: 100%;
  height: 100%;
  min-height: 290px;
  max-height: 290px;
  object-fit: cover;
}

.property-stuckone {
  margin-bottom: 11px;
}

.property-stuckone,
.property-stucktwo {
  height: 100%;
  min-height: 139px;
  max-height: 139px;
}

.property-stuckone img,
.property-stucktwo img {
  height: 100%;
  min-height: 139px;
  max-height: 139px;
  object-fit: cover;
}

.box-stuck {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-twwwo-btn {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.details-twwwo-btn button {
  width: 44%;
  height: 48px;
  font-weight: 600;
}

.properties-details-listone {
  padding: 17px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

.button-verification button {
  background: transparent;
  letter-spacing: 0px;
  color: #001978;
  opacity: 1;
  border-radius: 50px;
  border-color: #001978;
  font-size: 14px;
}

.button-verification {
  display: flex;
  gap: 8px;
  margin-top: 20px;
  padding: 20px 0px;
  border-top: 1px solid #e9e9e9;
}

span.min-con {
  letter-spacing: 0px;
  color: #072d8f;
  opacity: 1;
  font-weight: 600;
}

.notification-list li {
  list-style-type: disc;
  margin: 10px 0;
}

.notification-list li p {
  margin: 0;
}

.notification-list ul {
  padding-left: 19px;
  /* margin-top: 18px; */
}

.card-add-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

a.green-col {
  color: #05d48b;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  text-decoration: none;
}

.card-one {
  background: transparent
    linear-gradient(123deg, #2f5cfc 0%, #594ff5 56%, #7a45ef 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  width: 293px;
  height: 175px;
}

.card-lists ul {
  padding: 0;
  display: flex;
  gap: 16px;
}

.card-two {
  background: transparent linear-gradient(123deg, #04c3a3 0%, #1089de 100%) 0%
    0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  width: 293px;
  height: 175px;
}

.card-two,
.card-one {
  padding: 13px 20px;
}

.card-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 16px 0;
}

.card-dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.expriydate p {
  margin: 0;
  letter-spacing: 0.24px;
  color: #ffffff;
  font-size: 12px;
}

p.expy {
  opacity: 0.6;
}

.card-three {
  border: 1px dashed #b1b1b1;
  border-radius: 11px;
  opacity: 1;
  width: 293px;
  height: 175px;
}

.card-lists::-webkit-scrollbar {
  /* WebKit */
  width: 0px;
  height: 0px !important;
}
.card-lists {
  width: 100%;
  overflow-x: scroll;
}

.icon-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-lists::-webkit-scrollbar,
.appointment_content::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}

.card-lists::-webkit-scrollbar-track,
.appointment_content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.card-lists::-webkit-scrollbar-thumb,
.appointment_content::-webkit-scrollbar-thumb {
  background: #888;
}

.card-lists::-webkit-scrollbar-thumb:hover,
.appointment_content::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.card-add-btn h6,
.othewr-way h4,
.ad-card-details h4 {
  letter-spacing: 0.36px;
  color: #151515;
  opacity: 1;
  font-size: 18px;
  font-weight: 700;
}

.othewr-way {
  margin-top: 36px;
}

img.mastrocard {
  width: 10%;
}

.card-listsaa ul {
  padding: 0;
}

.card-listsaa {
  display: flex;
  justify-content: center;
}

.ad-card-details input {
  border-radius: 8px;
  opacity: 1;
  background: #f4f4f4;
  border: none;
  padding: 10px 20px;
}

.ad-card-details input:focus {
  border-radius: 8px;
  background: #f4f4f4 !important;
  border: none;
  box-shadow: unset;
}

.CVV-input input {
  width: 100px;
}

.ad-card-details label {
  letter-spacing: 0.13px;
  color: #190835;
  opacity: 1;
  font-weight: 600;
  font-size: 14px;
}

.ad-card-details {
  margin-top: 50px;
}

.buyersetting-left {
  padding: 20px;
}

.settig-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settig-sidebar h6 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.profile_menu-buyer ul {
  padding: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2d1159;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.BTN-rnables {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab-frame.buyer-setting label {
  letter-spacing: 0px;
  color: #989696;
  opacity: 1;
  font-size: 28px;
  font-weight: 600;
  padding: 8px 0;
  margin-right: 40px;
  font-family: "Roboto";
}

div.buyer-setting input:checked + label {
  border-bottom: 3px solid #151415;
  color: #151415;
  cursor: default;
}

.buyersetting-right {
  padding: 20px;
}

.saved-proeprty {
  display: flex;
  gap: 20px;
  padding: 15px;
  border: 1px solid #eae8e8;
  border-radius: 10px;
  margin-top: 24px;
}

.cont-right {
  width: 70%;
}

.imgw-left {
  width: 30%;
}

.heart-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heart-main h6 {
  letter-spacing: 0px;
  color: #001978;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 700;
  margin: 0;
}

.viewers {
  display: flex;
  gap: 20px;
  align-items: center;
}

.viewers p {
  margin: 0;
}

.imgw-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile_menu-buyer a.menu_list {
  margin: 10px 0;
}

.for-wrap {
  display: flex;
  align-items: center;
}

.for-wrap label {
  margin: 0;
  width: 30%;
  letter-spacing: 0.18px;
  color: #190835;
  opacity: 1;
  font-size: 20px;
}

.for-wrap input {
  border-radius: 8px;
  opacity: 1;
  background: #f4f4f4;
  padding: 13px;
  border: none;
}

.passwrod-chnge-form {
  margin-top: 30px;
}

button.update-password {
  border-radius: 8px;
  opacity: 1;
  background: #190835;
  display: block;
  margin: 30px auto 0;
  padding: 12px 30px;
}

.for-wrap input::placeholder {
  font-size: 16px;
  font-weight: 600;
}

.profile_menu-buyer {
  margin-top: 20px;
}

.close-icon {
  position: absolute;
  right: 12px;
  top: 10px;
}

.for-bg-col {
  background: #f8f8f8;
  padding: 13px 43px !important;
  border-radius: 9px;
  margin: 10px 0;
}

.for-bg-col label {
  margin: 0;
  display: block;
  cursor: pointer;
}

.status-vedio-col .modal-content {
  border-radius: 20px;
  padding: 10px;
}

.checkinline {
  display: flex;
  gap: 10px;
}

.location-ico {
  position: absolute;
  bottom: 12px;
  left: 10px;
}

.Builder-input input {
  padding: 10px 40px;
}

.filters-tag {
  background: #001978;
  border-radius: 20px;
  opacity: 1;
  padding: 5px 35px 5px 16px;
  color: #fff;
  display: flex;
  gap: 8px;
  align-items: center;
}

.filters-tag p {
  margin: 0;
  font-size: 14px;
}

.fillters {
  display: flex;
  gap: 8px;
}

.iterest-check input {
  padding: 8px;
}

.iterest-check label {
  font-size: 15px;
  font-weight: 600;
  color: #001978;
}

.iterest-check .form-check-input:checked {
  background-color: #001978;
  border-color: #001978;
}

.iterest-check .form-check {
  padding: 10px 35px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  margin-bottom: 10px;
}

.Builder-main {
  height: 500px;
  overflow-y: scroll;
}

.Builder-main::-webkit-scrollbar {
  width: 3px;
}

.Builder-main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.Builder-main::-webkit-scrollbar-thumb {
  background: #001978;
}

.Builder-main::-webkit-scrollbar-thumb:hover {
  background: #001978;
}

.nav-left {
  display: flex;
  width: 84%;
  align-items: center;
}

.nav-right {
  display: flex;
  align-items: center;
  width: 16%;
  justify-content: flex-end;
}

.slick-prev:before,
.slick-next:before {
  color: #111 !important;
  font-size: 40px !important;
}

.slick-list {
  margin-top: 19px !important;
}

.slick-prev {
  right: 41px !important;
  left: unset !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.slick-next {
  right: 5px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

button.slick-arrow {
  position: absolute;
  top: -50px;
  right: 0;
}

section.home-properties {
  padding: 95px 0;
  /* overflow-x: h; */
}
.slick-list {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.slick-list::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.social_icon {
  justify-content: flex-end;
}

.additional-features {
  display: flex;
}

.serch-btn-div {
  width: 70%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.search-main {
  width: 32%;
}

div.home-typframe input:checked + label {
  border: 2px solid #001878;
  color: #001878 !important;
}

div.home-typframe label {
  box-shadow: 0px 2px 7px #00000017;
  border-radius: 9px;
  width: 11.33%;
  margin: 10px;
  text-align: center;
  padding: 24px 0;
  letter-spacing: 0.16px;
  color: #393939 !important;
  opacity: 0.72;
  font-size: 15px !important;
  font-weight: 700;
  border: 2px solid #fff;
}
div.home-typframe label:nth-child(2) {
  margin-left: 0px;
}

.coomn-bgb svg {
  margin-bottom: 10px;
}

div.home-typframe input:checked + label .coomn-bgb svg path {
  fill: #001878;
  stroke: #001878;
}

button.buttom-addmusic-BTN {
  border: 1px solid #b5b5b5;
  border-radius: 10px;
  opacity: 1;
  background: transparent;
  width: 141px;
  height: 48px;
  color: #393939;
  padding: 11px 18px;
  font-weight: 500;
  box-shadow: 0px 3px 13px #0019781f;
}

.close-addmusic {
  position: absolute;
  right: 13px;
}

.addmuisic-modal-body {
  padding: 16px 30px;
}

.modal-head h3 {
  letter-spacing: -0.14px;
  color: #28272b;
  opacity: 1;
  font-weight: 700;
  font-family: Roboto;
}

.modal-head h6 {
  margin-bottom: 40px;
  letter-spacing: -0.07px;
  color: #28272b;
  opacity: 1;
  font-size: 14px;
}

.addmusic-modal .modal-content {
  border-radius: 24px;
  width: 448px;
}
.add_m_title {
  font: normal normal bold 20px/0px "Roboto";
  letter-spacing: -0.1px;
  color: #28272b;
}
.add_m_sub {
  font: normal normal normal 14px/30px "Roboto";
  letter-spacing: -0.07px;
  margin-bottom: 20px;
}

.music-list-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.music-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.music-col {
  display: flex;
  align-items: center;
  gap: 10px;
}

.music-iocn {
  background: #3399ff5c;
  border-radius: 10px;
}

.music-iocn {
  background: #3399ff5c;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.music-cont h5 {
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
  font-size: 16px;
}

.music-cont p {
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
  font: normal normal normal 12px/30px "Roboto";
  opacity: 50%;
}

.music-rigth p {
  margin: 0;
  color: #000000;
  margin: 0;
  font-size: 12px;
}

ul.music-list {
  padding: 0;
  margin: 0;
}

ul.music-list .form-check-input:checked {
  background-color: #072d8f !important;
  border-color: #072d8f !important;
}

ul.music-list .form-check-input {
  border-radius: 50px;
  padding: 9px;
}

.music-left .form-check {
  padding-top: 10px;
}

ul.music-list li {
  margin-bottom: 15px;
  padding-bottom: 15px;
}

ul.music-list li:not(:last-child) {
  /* border-bottom: 1px solid rgb(204, 204, 204); */
  border-bottom: 0.5px solid #d1d1d1;
}

.add-musicbnt button {
  width: 48%;
}

.custom-steeper {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 30px 11px 30px;
}

.active-step {
  background-color: rgb(0, 25, 120) !important;
}

.active-step svg path {
  fill: #fff;
}

.stepbox {
  width: 2rem !important;
  height: 2rem;
  border-radius: 6px;
  margin: 0px auto;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.common-step {
  position: relative;
}

.common-step:before {
  content: "";
  height: 3rem;
  width: 3rem;
  background: #fff;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: -8px;
  border-radius: 5px;
}

.common-step span {
  display: block;
  margin-top: 16px;
  text-align: center;
  /* font: normal normal normal 15px/22px Roboto; */
  letter-spacing: 0px;
  color: #222431;
  font-weight: 500;
}

.custom-steeper div {
  width: 20%;
}

.process {
  background: transparent;
  border: 2px solid #011a79;
  color: #011a79;
}

.uundeprocss {
  background: transparent;
  border: 2px solid #dfdfdf;
  color: #dfdfdf;
}

/* .step1::after {
  content: '';
  height: 2px;
  width: 81%;
  background: #011a79;
  position: absolute;
  top: 15px;
  right: -103px;
} */
.step1::after,
.step2::after,
.step3::after,
.step4::after {
  content: "";
  height: 1px;
  width: 81%;
  background: #e0e0e0;
  position: absolute;
  top: 15px;
  right: -103px;
}

.active-line::after {
  height: 2px;
  background: #011a79;
}

.wraping-col .propety-details-box {
  padding: 0 60px;
  box-shadow: unset;
}

button.adaa.btn.btn-secondary {
  background-color: transparent !important;
  color: #111;
  border: none;
  padding: 0;
}

button.adaa.btn.btn-secondary:hover {
  background-color: transparent !important;
}

button.adaa.btn.btn-secondary:focus {
  box-shadow: unset;
}

.heading-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.drops-area form {
  display: flex;
  gap: 16px;
}

.drops-area div {
  margin: 0 !important;
}

.drops-area select {
  border-radius: 10px;
  background: #001978;
  color: #fff;
  padding: 8px 36px 8px 13px;
}

.caret {
  position: absolute;
  right: 9px;
  top: 8px;
}

.heading-area h4 {
  letter-spacing: 0.28px;
  color: #393939;
  font-size: 28px;
  font-family: 700;
  font-family: Quart, sans-serif;
}

.aeeaa-boxss {
  border-radius: 9px;
  box-shadow: 5px 7px 24px #52505e17;
  background: #ffffff;
  padding: 40px;
}

.areapart-main {
  display: flex;
  gap: 20px;
}

.ima-area,
.ima-area img {
  width: 110px;
  height: 99px;
  object-fit: cover;
  border-radius: 5px;
}

.dateprice-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateprice-area p {
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 0;
}

.dateprice-area h3 {
  letter-spacing: 0.01px;
  color: #05d48b;
  margin: 0;
}

.contet-area p {
  margin: 0;
  color: #231f1f;
}

.contet-area p strong {
  opacity: 1 !important;
  color: #111;
  font-size: 15px;
}

.areapart-main {
  display: flex;
  gap: 20px;
  border: 1px solid #dcdcdc8c;
  border-radius: 10px;
  opacity: 1;
  padding: 18px;
  justify-content: space-between;
}

.contet-area {
  width: 80%;
}

.aeeaa-boxss .row {
  row-gap: 24px;
}

.areaprice-main a {
  color: #001978;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
}

p.selected_date_title {
  letter-spacing: 0px;
  color: #151415;
  opacity: 1;
  font-size: 22px;
  font-weight: 700;
}

.top_right_card span {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 13px;
  color: #5d5d5d;
  opacity: 1;
}

p.appointment_heading {
  font-weight: 700;
  margin-top: 20px;
}

.heading-newoffer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-left-offer {
  display: flex;
  align-items: center;
  gap: 18px;
}

.filter p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 3px;
}

.heading-left-offer h4 {
  letter-spacing: 0px;
  color: #151415;
  opacity: 1;
  font-size: 20px;
  margin: 0;
  font-weight: 600;
}

.list-box {
  display: flex;
  background: #f8f8f8;
  border-radius: 11px;
  opacity: 1;
  padding: 16px;
  gap: 30px;
  width: 70%;
}

.left-list-house {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-img-house img {
  width: 75px;
  height: 65px;
  object-fit: cover;
  border-radius: 5px;
}

.left-img-house {
  width: 75px;
  height: 65px;
}

h5.green-col {
  letter-spacing: 0.01px;
  color: #05d48b;
  opacity: 1;
  margin: 0;
}

.right-list-house {
  display: flex;
  align-items: center;
  gap: 10px;
  border-left: 1px solid #ccc;
  padding-left: 20px;
}

.right-img-personconts p {
  margin: 0;
  font-size: 14px;
}

.right-img-personconts p strong {
  font-size: 16px;
}

.new-ffwe-list li {
  list-style-type: disc;
  margin: 0px 0 30px;
}

.new-ffwe-list ul {
  padding-left: 18px;
  margin-top: 17px;
}

p.plus-prop {
  margin: 0;
  font-weight: 700;
}

.slick-next:before {
  content: unset !important;
}

.slick-prev:before {
  content: unset !important;
}

.slick-prev,
.slick-next {
  top: -35px !important;
  border: 1px solid #d9d9d9 !important;
  height: 31px !important;
  width: 37px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

section.Buyerwlcmid-main {
  box-shadow: 5px 7px 24px #52505e17;
  border-radius: 9px;
  opacity: 1;
  width: 100%;
  margin: 70px auto;
  border-radius: 15px;
}

.welcome-left {
  background: #190835;
  text-align: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  height: 100%;
  position: relative;
}

.welcome-right {
  padding: 58px;
}

.welcome-left img {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px auto;
}

.welcome-left h4 {
  padding: 98px 0 0;
  color: #fff;
}

button.next-btn {
  padding: 14px;
  width: 40%;
  background: #1c0434;
  border-radius: 10px;
  opacity: 1;
  border: none;
}

.hapy-cool p {
  font-weight: 700;
  font-family: "Roboto";
}

.welcome-right h4 {
  opacity: 1;
  font: normal normal medium 24px/24px "Roboto";
  letter-spacing: 0.24px;
  padding-bottom: 20px;
  color: #190835;
}

p.color-aprt {
  letter-spacing: 0.16px;
  color: #001978;
  opacity: 1;
  font-weight: 600;
}

.welcome-right ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.welcome-right ul li {
  flex: 1 0 33%;
}

.nuumber-col {
  letter-spacing: 0px;
  color: #001978;
  opacity: 1;
  border: 1px solid;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 600;
}

.main-number {
  display: flex;
  gap: 11px;
  align-items: center;
}

.main-number p {
  margin: 0;
  font-weight: 600;
}

p.bottom-text {
  /* color: #6c6c6c; */
  /* font-weight: 400; */
  font: normal normal normal 16px/28px "Roboto";
  letter-spacing: 0.16px;
  color: #190835;
}

.head-col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 81px;
}

.head-col a {
  letter-spacing: 0.18px;
  color: #1c0434;
  opacity: 1;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.18px;
  font-weight: 600;
  text-decoration: underline;
}

p.service-para {
  letter-spacing: 0.14px;
  color: #190835;
  opacity: 0.9;
  margin: 0px 0 30px;
}

.additional_feature input,
.additional_feature2 input {
  accent-color: #011a79;
}

a.crete-account {
  text-decoration: none;
  color: #1c0434;
}
a.crete-account2 {
  /* text-decoration: none; */
  color: #1c0434;
}

form.sign-input input {
  display: block !important;
}

form.sign-input label {
  padding: 0 !important;
}

form.sign-input {
  margin-top: 15px;
}

.clearfix.sign-clear {
  /* opacity: 0.11; */
  background: #efefef;
  width: max-content;
  min-width: -webkit-fill-available;
  border-radius: 16px;
  padding: 7px 8px;
}

div.sign-setting input:checked + label {
  box-shadow: 0px 6px 19px #00000017;
  border-radius: 16px;
  border: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 19px #00000017;
  padding: 5px 21px;
  font: normal normal bold 18px/34px "Roboto";
  letter-spacing: 0px;
  color: #001878;
  opacity: 1;
}

div.sign-setting label {
  font: normal normal normal 16px/34px Roboto;
  letter-spacing: 0px;
  color: #656565;
  opacity: 1;
  padding: 5px 34px;
  width: 50%;
  text-align: center;
}

.login_modal .modal-header {
  display: block;
}

.forgotmodal .modal-header {
  padding: 40px 18px 0;
}

p.modal-des {
  font: normal normal normal 16px/23px "Roboto";
  /* font-size: 16px; */
  letter-spacing: 0.02px;
  color: #9d9c9c;
  opacity: 1;
  margin: 0;
}

.btn-group-div.mt-5 {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn-group-div button {
  width: 49%;
  margin: 0;
  border-radius: 8px;
}

button.back_btn {
  border: 0.5px solid #9d9c9c;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal medium 16px/34px "Roboto";
  opacity: 1;
  padding: 8px;
  height: 56px;
  color: #6c6c6c;
}

.input-groupaa input {
  text-align: center;
  padding: 10px;
  border: 1px solid #001978 !important;
  border-radius: 10px;
  opacity: 1;
  font: normal normal bold 28px/34px Roboto;
  letter-spacing: 0px;
  color: #3a3b52 !important;
  opacity: 1;
}

.input-groupaa {
  display: flex;
  gap: 10px;
  width: 70%;
  margin: 0px auto;
}

.input-groupaa input::-webkit-outer-spin-button,
.input-groupaa input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.appointment_right_wrapper {
  width: 72% !important;
}

button.lockright {
  background: #1c0434 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00197821;
  border-radius: 10px;
  height: 48px;
  width: 48px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.go-back-div.epcsellerback {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.epcsellermain h5 {
  font: normal normal normal 28px/30px Quart;
  letter-spacing: 0.28px;
  color: #393939;
}
.wrap-boxepcselller {
  width: 70%;
}
.epcsellermain .floor-img-upload {
  height: 150px;
}
.epc-service h5 {
  letter-spacing: 0px;
  color: #151414;
  font-size: 12px;
  margin: 0;
  font-weight: 300 !important;
  line-height: 18px;
}
.epc-service-main {
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.epc-service h4 {
  letter-spacing: 0px;
  color: #05d48b;
  font-size: 28px;
  font-family: "Roboto";
}
.book-now-wrap {
  display: flex;
  align-items: center;
  margin: 10px auto;
  justify-content: center;
}
button.Book-Now {
  border: 0.5px solid #1c043457;
  border-radius: 7px;
  background: transparent;
  letter-spacing: 0.18px;
  color: #001978;
  font-size: 14px;
  font-weight: 700;
  padding: 11px 20px;
}
.epcsellermain .epc-main-right {
  box-shadow: 0px 5px 14px #00000014;
  border: 1px solid #001978;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mortage-frm {
  margin-top: 30px;
}
.wrpslect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slext,
.slexttwo {
  background: #001978 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 49%;
  border: none;
  padding: 12px 18px;
  font: normal normal normal 15px/21px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
}
.slexttwo {
  border-radius: 8px;
  width: 49%;
  background: #f4f4f4;
  color: #636363;
}
.wrapresult {
  background: #dce4f7 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 40px 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.wrapresult input {
  width: 340px;
  box-shadow: 0px 0px 15px #00000014;
}
.wrapresult-head h6 {
  letter-spacing: 0px;
  color: #000000;
  font-size: 17px;
  font-weight: 700;
  font-family: "Roboto";
}
.wrapresult-head p {
  font: normal normal normal 15px/18px Roboto;
  letter-spacing: 0px;
  color: #6c6c6c;
  margin: 0;
}
.wrapresult div {
  margin: 0 !important;
}
.qutaion-partcenter {
  background: #fcfcfe 0% 0% no-repeat padding-box;
  border: 1px solid #70707036;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 50px 0;
}
.quotioncommon {
  font: normal normal normal 14px/22px Roboto;
  letter-spacing: 0px;
  color: #151414;
}
.quobox1.quotioncommon {
  text-align: center;
}
.quobox1.quotioncommon p {
  margin-bottom: 5px;
  font-size: 12px;
}
.quobox1.quotioncommon h5 {
  font: normal normal bold 18px/22px Roboto;
  letter-spacing: 0.36px;
  color: #05d48b;
}
p.quationpara {
  margin: 0;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #151415;
  text-align: center;
  padding: 20px 150px;
}
.quation-btn {
  margin-top: 90px;
}
p.quation-sub {
  font: normal normal normal 18px/27px Roboto;
  letter-spacing: 0px;
  color: #151415;
}
.create-floorplan {
  padding: 39px 0;
}
.seelerfloorplan .create-floorplan {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #001978;
}
.create-floorplan p,
.Purchase-floorplan p {
  letter-spacing: 0px;
  color: #001978;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: 600;
  font-family: "Roboto";
}
.Purchase-floorplan p {
  padding: 44px 0;
  margin: 0;
}
form.inputchek {
  position: absolute;
  right: 0;
  top: 5px;
}
form.inputchek .form-check-input:checked {
  background-color: #001978;
  border-color: #001978;
}
.cleaner-frm {
  margin-top: 40px;
}
.cleaner-frm input {
  text-align: center;
  width: 40%;
  margin: 11px auto 0;
}
p.cler-sub {
  font: normal normal normal 19px/27px Roboto;
  letter-spacing: 0px;
  color: #9d9c9c;
}
.total-herr {
  margin-top: 50px;
}
.total-herr p {
  font: normal normal 300 16px/32px Roboto;
  letter-spacing: 0px;
  color: #6c6c6c;
}
.total-herr p span {
  font: normal normal bold 22px/27px Roboto;
  letter-spacing: 0px;
  color: #05d48b;
}
.pro-valuation h5 {
  font: normal normal normal 28px/26px Quart;
  letter-spacing: 0.28px;
  color: #393939;
}
.epc-service h5 {
  letter-spacing: 0px;
  color: #151414;
  font-size: 13px;
  line-height: 22px;
}
.pro-valuation .floor-img-upload {
  height: 150px;
}
.pro-valuation .epc-service h4 {
  font-size: 23px;
}
.pro-valuation .epc-service-main {
  align-items: flex-start;
}
.pro-valuation .epc-main-right {
  background: #fcfcfe 0% 0% no-repeat padding-box;
  border: 1px solid #70707036;
  border-radius: 12px;
}
.wrap-date {
  width: 43%;
}
.wrap-date .floor-plan-btns button {
  width: 44%;
  padding: 15px;
  border-radius: 8px;
  margin-top: 30px;
}
.monthselector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0;
}
.monthselector p {
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #a5acb9;
  margin: 0;
}
p.activedate {
  letter-spacing: 0px;
  color: #344eb1;
  font-size: 16px;
}
.calende-availalbity .sdp--month-picker {
  display: none;
}
p.slots-available {
  letter-spacing: 0px;
  color: #a5acb9;
  font-size: 13px;
  font-family: "Roboto";
  margin: 0;
}
.slot-available ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
li.activeslot {
  padding: 5px;
  background: #344eb1;
  border-radius: 5px;
  color: #fff !important;
}
.slot-available {
  padding-top: 10px;
}
.slot-available ul li {
  letter-spacing: 0.01px;
  color: #0c233c;
  font-size: 15px;
  font-weight: 500;
}
.broker-main .epc-service-main {
  padding: 0;
  border-bottom: 1px solid #cccccc61;
  align-items: flex-start;
}
.broker-main .epc-main-right {
  padding: 20px 10px;
  box-shadow: 0px 5px 14px #00000014;
  border: 1px solid #001978;
  border-radius: 8px;
  height: 120px;
}
.broker-main .epc-service h5 {
  letter-spacing: 0px;
  color: #151414;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 500 !important;
  line-height: 26px;
}
.broker-bok button.Book-Now {
  border: 0.5px solid #1c043457;
  border-radius: 7px;
  color: #fff;
  letter-spacing: 0.18px;
  background: #001978;
  font-size: 14px;
  font-weight: 700;
  padding: 11px 20px;
  margin-top: 20px;
}
.broker-main h5 {
  font: normal normal normal 28px/76px Quart;
  letter-spacing: 0.28px;
  color: #393939;
}
.broker-main .service-rating {
  padding: 10px 0;
}
.wrap-boxepcselller .floor-upload {
  padding: 10px 0 70px;
}
.Ownership-status h5 {
  font: normal normal bold 28px/27px Roboto;
  letter-spacing: 0px;
  color: #151415;
}
.Ownership-status p {
  font: normal normal 300 17px/26px Roboto;
  letter-spacing: 0.18px;
  color: #190835;
}
.ownertype .additional-features div {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 9px;
  border: none;
  width: 33.33%;
  padding: 15px;
  box-shadow: unset;
}
.coownertype .additional-features {
  justify-content: center;
}
.coownertype .additional-features div {
  padding: 25px 30px;
  width: auto;
  box-shadow: unset;
}
.user-here {
  position: absolute;
  top: 9px;
  left: 14px;
}
.coower-form input {
  padding-left: 43px !important;
}
a.addco-oeen {
  text-decoration: underline;
  letter-spacing: 0.18px;
  color: #1c0434;
  font-size: 16px;
  font-family: "Roboto";
}
ul.budget-item-list {
  padding: 0;
  margin: 0;
  margin: 40px 0 !important;
}
ul.budget-item-list li {
  list-style-type: none;
}
.listwrap-budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.budget-left,
.budget-right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.budgetname p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  letter-spacing: 0px;
  color: #1f1d1d;
}
img.bars-img {
  width: 30px;
}
.budgetname {
  display: flex;
  align-items: center;
  gap: 10px;
}
.rating-budgt p {
  margin: 0;
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
}
.budget-right h4 {
  letter-spacing: 0.44px;
  color: #05d48b;
  font-size: 22px;
  margin: 0;
  font-weight: 700;
}
button.btn-detele {
  padding: 0;
  background: none;
  border: none;
}

button.btn-detele:hover {
  background: none !important;
  color: #686868 !important;
}
ul.budget-item-list li {
  padding: 20px 0;
}

ul.budget-item-list li:not(:last-child) {
  border-bottom: 1px solid #e0dddd4a;
}
.budget-btn {
  align-items: center;
}
.budget-btn p {
  margin: 0;
}
.budget-btn button {
  margin-top: 0 !important;
}
.epc-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.epc-head h5 {
  font: normal normal normal 28px/76px Quart;
  letter-spacing: 0.28px;
  color: #393939;
  margin: 0;
}
.epc-wrap {
  width: 70%;
}
.details-solicotr-box h5 {
  font: normal normal bold 28px/27px Roboto;
  letter-spacing: 0px;
  color: #151415;
  margin-bottom: 40px;
}

.tag-input {
  width: 100%;
  background: #f4f4f4;
  border: none;
  font-size: 17px;
  font-weight: 500;
  color: #131212;
  box-shadow: none;
  border-radius: 8px;
  height: 56px;
  padding: 0 15px;
  margin-top: 10px;
}

.tags-dropdown {
  width: 100%;
}

.tags-dropdown > .css-t3ipsp-control,
.tags-dropdown > .css-13cymwt-control {
  background: #f4f4f4;
  border: none;
  font-size: 17px;
  font-weight: 500;
  color: #131212;
  box-shadow: none;
  border-radius: 8px;
  height: 56px;
  padding-left: 5px;
  margin-top: 10px;
}

.tags-dropdown > .css-t3ipsp-control:hover {
  border: none;
  border-color: transparent;
  outline: none;
}

.tags-dropdown
  > .css-13cymwt-control
  > .css-1hb7zxy-IndicatorsContainer
  > .css-1u9des2-indicatorSeparator,
.tags-dropdown
  > .css-t3ipsp-control
  > .css-1hb7zxy-IndicatorsContainer
  > .css-1u9des2-indicatorSeparator {
  display: none;
}

.tags-dropdown
  > .css-13cymwt-control
  > .css-1hb7zxy-IndicatorsContainer
  > .css-1xc3v61-indicatorContainer {
  color: #322f2f;
}

.tags-dropdown
  > .css-13cymwt-control
  > .css-1hb7zxy-IndicatorsContainer
  > .css-1xc3v61-indicatorContainer:hover,
.tags-dropdown
  > .css-13cymwt-control
  > .css-1hb7zxy-IndicatorsContainer
  > .css-15lsz6c-indicatorContainer:hover {
  color: #322f2f;
}

/* responsive start */
@media only screen and (max-width: 1280px) {
  .imgbox {
    flex: 1 0 195px;
  }

  .form-heading-steo4 {
    padding: 20px 0px;
  }

  .uploaded-images {
    padding: 0 0px;
  }

  .step1::after,
  .step2::after,
  .step3::after,
  .step4::after {
    width: 78.5%;
    right: -84px;
  }

  .list_start {
    padding: 0 69px;
  }

  .form-heading,
  form.confirmation-form {
    padding: 27px 0px;
  }

  section.propety-detailstep .container {
    padding: 0;
  }

  div.home-typframe label {
    width: 14%;
  }

  .services_section {
    padding: 0 40px;
  }

  .seller_container {
    padding: 50px 0px;
  }

  .wraping-col {
    padding: 0 69px !important;
  }

  .wraping-col .propety-details-box {
    padding: 0 0px;
  }

  .contet-area p strong {
    font-size: 14px;
  }

  .contet-area p {
    font-size: 15px;
  }

  .list_title {
    padding: 0px 6px;
    margin-top: 75px;
  }

  .box_shadow_wrapper {
    width: 98%;
  }

  .appointment_wrapper {
    padding: 0 13px;
  }

  .card-lists::-webkit-scrollbar,
  .appointment_content::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .card-lists::-webkit-scrollbar-track,
  .appointment_content::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .card-lists::-webkit-scrollbar-thumb,
  .appointment_content::-webkit-scrollbar-thumb {
    background: #fff;
  }

  .card-lists::-webkit-scrollbar-thumb:hover,
  .appointment_content::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }

  .profile_right_side {
    padding: 28px;
  }

  .box1 {
    width: 148px;
    height: 139px;
  }

  section.Buyerwlcmid-main {
    max-width: 95%;
  }

  .address-ownership {
    padding: 27px 80px !important;
  }
}

@media only screen and (max-width: 1169px) {
  .nav-left {
    width: 78%;
  }

  .nav-right {
    width: 22%;
  }

  section.Faq-center {
    padding: 80px 0 80px;
  }

  .FAQ-treand {
    padding: 0 0px !important;
  }

  .seller_container {
    padding: 21px;
  }

  .profile_left_side,
  .profile_top_card {
    width: 300px;
  }

  .profile_right_side {
    width: 68%;
  }

  a.property_btn,
  .logIn_btn {
    width: 48%;
  }

  .card-lists {
    width: 100%;
  }

  .search-main {
    width: 72%;
  }

  .left-reply-offer .message-bg {
    width: 85%;
  }

  .btn-crete-offer {
    width: 24%;
  }

  .input-form-main {
    width: 87%;
  }

  .cont-unser {
    width: 124px;
  }

  .tabing-clear {
    padding: 0px 10px 10px 10px;
  }

  section.inbox-dashboard {
    padding: 40px 0 40px;
  }

  .offer-coditions label {
    width: 48%;
  }

  .imgbox {
    flex: 1 0 193px;
  }
}

@media only screen and (max-width: 1023px) {
  .nav_bg .container {
    max-width: 920px !important;
  }

  .hero_container {
    background-position: 390px;
  }

  .video_wrapper {
    height: 90vh;
  }

  form.search-input {
    width: 100%;
  }

  section.home-properties .container,
  .container {
    max-width: 860px !important;
  }

  form.confirmation-form {
    padding: 0 0px;
  }

  .form-heading-steo4 {
    padding: 20px 0px;
  }

  .uploaded-images {
    padding: 0;
  }

  .profile_right_side {
    width: 64%;
  }

  .listing_texts {
    display: block;
  }

  .listing_right {
    padding-right: 0;
    padding-top: 10px;
  }

  .listing_texts img {
    width: 100%;
    height: 240px;
  }

  .serch-btn-div {
    gap: 5px;
  }

  .inbox-board-sidebar {
    width: 35%;
  }

  .inbox-board-chatscreen {
    width: 65%;
    padding: 11px;
  }

  .two-btn-oofer button {
    width: 48%;
  }

  .input-form-main {
    width: 70%;
  }

  .appointment_wrapper {
    padding: 0 0px;
  }

  .appointment_right_wrapper {
    width: 64% !important;
  }
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 979px) {
  .nav_bg .container {
    max-width: 672px !important;
  }

  section.home-properties .container,
  .container {
    max-width: 740px !important;
  }

  .video_wrapper {
    height: 70vh;
  }

  .login_info {
    display: block;
    padding: 30px 100px;
  }

  .footer_container ul {
    padding: 0;
  }

  .hero_container {
    background-position: 220px;
  }

  .list-main-under {
    padding: 70px 20px;
  }

  .form-heading {
    padding: 27px 10px;
  }

  .suggestion-value {
    width: 46%;
  }

  .faq-frame .clearfix {
    display: flex;
    flex-wrap: wrap;
  }

  .faq-frame .clearfix label {
    flex: 1 0 156px;
  }

  .box1 {
    width: 324px;
  }

  .search-main,
  .serch-btn-div {
    width: 100%;
  }

  form.serach-fitler-form {
    display: block;
  }

  .serch-btn-div {
    margin-top: 10px;
  }

  .inbox-board-sidebar {
    width: 45%;
  }

  .btn-crete-offer {
    width: 34%;
  }

  .message-footer {
    gap: 7px;
  }
}

@media only screen and (max-width: 799px) {
  .steps-btn button {
    width: 35%;
  }

  .profile_left_side,
  .profile_top_card {
    width: 280px;
  }

  .profile_right_side {
    padding: 20px;
  }

  .property-stuckleft {
    width: 100%;
  }

  .property-stuckrigth {
    width: 100%;
    display: flex;
    margin-top: 10px;
    gap: 10px;
  }

  .details-twwwo-btn button {
    width: 100%;
  }

  button.edit-details,
  button.view-more {
    padding: 10px 18px;
  }

  .appointment_right_wrapper {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .hero_container {
    padding: 40px 0 40px 40px;
  }

  .video_wrapper {
    height: 60vh;
  }

  .nav_bg .container {
    max-width: 562px !important;
  }

  .slick-prev {
    right: 70px !important;
  }

  button.slick-arrow {
    right: 30px;
  }

  .login_info {
    text-align: center;
  }

  .login_btn {
    margin-top: 20px;
  }

  .footer_container ul li {
    margin: 8px auto;
  }

  .hero_container h1 {
    font-size: 2rem;
  }

  .right-box-img {
    margin-top: 20px;
  }

  .near-list-main {
    display: block;
  }

  .near-list-one:not(:last-child) {
    margin-bottom: 20px;
  }

  section.common-steps-main {
    padding: 50px 0;
  }

  .faq-heading {
    padding: 50px 0;
  }

  .profile_left_side,
  .profile_top_card {
    width: 100%;
  }

  .profile_right_side {
    width: 100%;
  }

  .seller_container {
    display: block;
  }

  .profile_menu {
    padding: 10px 30px;
  }

  .profile_right_side {
    margin-top: 20px;
  }

  .box1 {
    width: 264px;
  }

  .vedio-verification {
    margin-top: 10px;
  }

  .plans .plan {
    width: 35%;
  }

  .fourth-filter.dropdown-menu.show {
    left: 0px !important;
  }

  .inbox-board-sidebar,
  .inbox-board-chatscreen {
    width: 100%;
  }

  .inbox-board-main {
    display: block;
  }

  .inbox-board-chatscreen {
    margin-top: 30px;
  }

  .services_section {
    padding: 0 0px;
  }

  .heading-area {
    display: block;
    margin: 30px 0 20px;
  }

  .welcome-left img {
    position: unset;
  }

  .welcome-left {
    border-radius: unset;
  }

  .welcome-right {
    padding: 28px;
  }

  .my-con.container {
    max-width: 530px !important;
  }
}

@media only screen and (max-width: 639px) {
  .nav_bg .container {
    max-width: 412px !important;
  }

  .hero_container {
    background-image: unset;
  }

  .hero_content {
    text-align: center;
    width: 94%;
  }

  h1 {
    font-family: Quart, sans-serif !important;
  }

  button.btn-buy,
  button.btn-sell {
    padding: 9px 25px;
    height: 49px;
  }

  form.search-input input {
    padding: 15px 195px 22px 75px;
  }

  .searchicon {
    top: 15px;
  }

  section.banner-search {
    padding: 35px 20px;
  }

  .video_wrapper {
    height: 40vh;
  }

  .guid_container {
    padding: 50px;
    height: 100%;
    min-height: 770px;
    background-position: center;
  }

  .login_info {
    padding: 30px 10px;
  }

  .login_btn {
    margin-top: 20px !important;
    margin: 0;
  }

  .footer_copyright_section {
    text-align: center;
  }

  .social_icon {
    justify-content: center;
    margin-top: 10px;
  }

  .list_start {
    padding: 0 30px;
  }

  .additional-features {
    display: block;
  }

  .additional-features div {
    width: 100%;
    margin-bottom: 10px;
  }

  .suggestion-value {
    width: 100%;
    margin-bottom: 18px;
  }

  .form-heading,
  .list-heading,
  .reals-me-first {
    display: block;
  }

  .steps-btn button {
    width: 100%;
  }

  .propety-details-box {
    box-shadow: unset;
    padding: 0;
  }

  .appointment_card {
    max-width: 100%;
  }

  .appointment_wrapper {
    padding: 0 42px;
  }

  .appointment_right_wrapper {
    margin-bottom: 53px !important;
  }

  .box1 {
    width: 188px;
  }

  div.verify-frame label {
    font-size: 20px;
  }

  div.tab-new-verrify label {
    font-size: 18px;
  }

  section.Floorplanmid-main {
    padding: 30px 0;
  }

  .fourth-filter.dropdown-menu.show {
    min-width: 430px;
    left: -30px !important;
  }

  .result-div {
    display: block;
  }

  button.slick-arrow {
    position: absolute;
    top: 50%;
  }

  .slick-prev {
    left: 5px !important;
    z-index: 2;
  }
}

@media only screen and (max-width: 479px) {
  .nav_bg .container {
    max-width: 372px !important;
  }
  .welcome-right {
    height: 500px;
  }

  .hero_container {
    padding: 30px 30px 30px 30px;
  }

  .hero_content {
    width: 100%;
  }

  .hero_content {
    text-align: center;
    width: 100%;
  }

  form.search-input input {
    padding: 17px 175px 21px 68px;
  }

  section.banner-search {
    padding: 35px 10px;
  }

  .video_wrapper {
    height: 37vh;
  }

  .StepperContainer-0-2-1 {
    padding: 0 !important;
  }

  .list_title {
    padding: 0px 28px;
  }

  .form-heading-steo4 {
    display: block;
  }

  section.propety-detailstep .container {
    padding: 0;
  }

  .img-list ul li img {
    height: 45px;
  }

  .box1 {
    width: 305px;
  }

  div.verify-frame label {
    font-size: 16px;
  }

  .tabing-clear {
    padding: 0;
  }

  .plans .plan {
    width: 45%;
  }

  .check-bg {
    width: 100%;
  }

  .common-btn-drop button.btn.btn-secondary {
    width: 179px;
  }

  .third-menu {
    left: 0px !important;
  }

  .fourth-filter.dropdown-menu.show {
    min-width: 380px;
    left: 0px !important;
  }

  .my-rely-box figure.avatar {
    height: auto;
    width: 150px;
  }

  .left-reply-offer .message-bg,
  .message-bg {
    width: 100%;
  }

  .two-btn-oofer button,
  .input-form-main {
    width: 100%;
  }

  button.accept-btn {
    margin-left: 0px;
    margin-top: 10px;
  }

  .message-footer {
    display: block;
  }

  .btn-crete-offer {
    width: 100%;
    margin-top: 10px;
  }

  .offer-coditions label {
    width: 100%;
  }

  .search_input_box .input-group {
    display: block;
  }

  .search_input_box .input-group input {
    width: 100%;
  }

  .search_services input {
    max-width: 100%;
  }

  .contact_submit2 {
    margin-left: 0px !important;
    width: 100%;
    margin-top: 14px;
  }

  .search_input_box {
    margin: 0;
    padding: 20px 0;
  }

  .search_services {
    height: unset;
  }

  .aeeaa-boxss {
    padding: 10px;
  }

  .areapart-main {
    display: block;
  }

  .contet-area {
    width: 100%;
    margin-top: 16px !important;
  }

  .welcome-right ul li {
    flex: 1 0 100%;
  }

  .address-ownership {
    padding: 27px 30px !important;
  }
}

@media only screen and (max-width: 375px) {
  .common-btn-drop button.btn.btn-secondary {
    width: 100%;
  }

  .fourth-filter.dropdown-menu.show {
    min-width: 320px;
    left: 0px !important;
  }

  .nav_bg .container {
    max-width: 322px !important;
  }
}

/* responsive start */

/* css fixed  */
.slick-arrow svg {
  width: 20px;
  height: 20px;
}

.navbar-light .navbar-nav .nav-link.login-sign::after {
  content: "";
  position: absolute;
  left: 0;
  opacity: 0;
}

/* validation  */

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: none !important;
  border: 1px solid;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid;
  border-color: red;
}

.sign-setting label {
  font-family: "Poppins", sans-serif !important;
}

.tessst {
  /* max-width: 90% !important; */
}

.newPass {
  /* background: #ebebeb; */
}

.input-groupaa2 input {
  width: 3.8rem !important;
  margin-right: 10px;
  font: 28px;
  height: 70px;
  background: transparent;
}

.login-sign {
  margin-left: 11px;
}

.card-heading {
  font: normal normal 550 17px/20px "Roboto", sans-serif !important;
  color: #231f1f !important;
}
.new_class {
  padding: 0 69px;
}
.sdp--month-name {
  font-weight: 700;
  color: #001978;
}
.sdp--square-btn__shadowed {
  background: #130f26 0% 0% no-repeat padding-box !important;
  color: #fff !important;
  width: 25px !important;
  height: 25px !important;
}
p.sdp--text__inactive {
  font: normal normal medium 14px/17px "Roboto" !important;
  letter-spacing: 0px;
  margin-bottom: 10px !important;
  color: #001978 !important;
}
.evidence-proof ul li::marker {
  font-size: 1.5rem;
}
.name-address-modal .modal-dialog {
  width: 419px;
}
.name-address-modal .modal-header {
  border: none;
}
.name_addres {
  text-align: left;
  font: normal normal bold 26px "Roboto";
  letter-spacing: 0.23px;
  color: #190835;
}
.close_btn {
  cursor: pointer;
}
.go-back-div {
  cursor: pointer;
}
.epcseller_div .floor-img-upload {
  width: 42%;
}
@media only screen and (min-width: 1169px) {
  .wrapresult {
    padding: 40px 50px;
  }
}
@media only screen and (min-width: 1200px) {
  .floorplan-box {
    /* padding: 36px 17px; */
  }
}
.notification-list ul li::marker {
  font-size: 1.5rem;
}
.bottom_arrow_right {
}
.bottom_arrow_right img {
  width: 15px;
  height: 15px;

  transform: rotate(178deg) !important;
}
.phoneNumber_select {
  display: flex;
}

.country_code {
  max-width: 70px;
}
.login_modal2 .modal-content {
  padding: 14px;
}

.new_te {
  font: normal normal normal 15px/18px "Roboto";
  letter-spacing: 0px;
  color: #9d9c9c;
}
.buyer_home_head {
  width: 100%;
  height: 46px;
  margin: 20px 0 33px 0;
  text-align: center;
  font: normal normal normal 30px "Quart";
  letter-spacing: 0.34px;
  color: #190835;
}
.new_csdd {
  padding-bottom: 95px;
}
.new_csdd .plans .plan {
  flex: 1;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001a;
  /* border: 1px solid #70707033; */
  border-radius: 11px;
}
.new_csdd .plans .plan-content {
  min-height: 201px;
}

.new_csdd .plans .plan .plan-details span {
  margin-bottom: 10px;
  display: inline-block;
  display: block;
  font: normal normal medium 16px/21px "Roboto";
  line-height: 24px;
  font-weight: 500;
  color: #131212;
}
.slick-slider {
  margin-left: -10px !important;
}
.new_csdd .plans .plan .plan-content {
  text-align: center;
  padding: 30px 0;
  box-sizing: border-box;
  border: 1px solid #70707033;
  display: flex;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  transition: box-shadow 0.4s;
  justify-content: space-between;
  position: relative;
}
.new_csdd .plans .plan .plan-content img {
  width: 72px;
}
.new_csdd h4 {
  font: normal normal bold 22px "Roboto";
  letter-spacing: 0.2px;
  color: #190735;
}
.carousel-indicators button {
  width: 8px !important;
  height: 8px !important;
  border: none !important;
  margin-bottom: 10px !important;
  border-radius: 50px !important;
}

.carousel-indicators .active {
  width: 30px !important;
  opacity: 1;
}
.fixed_text {
  letter-spacing: 0px;
  border: 0.5px solid #9d9c9c !important;
  /* font: normal normal medium 16px/34px "Roboto" !important; */
  font-weight: 500 !important;
  color: #6c6c6c !important;
}

.text22 {
  font: normal normal bold 16px/34px "Roboto" !important;
}
.newTextt {
  font: normal normal normal 16px "Roboto";
  font-weight: 500;
}

.counter_btn {
  font-size: 24px;
  display: inline-block;
  /* width: 61px; */
  padding: 8px 20px;
  font-weight: 700;
  /* height: 56px; */
  position: relative;
  /* box-sizing: border-box; */
}

.wlecome_board {
}

@media only screen and (max-width: 1440px) {
  /* .welcome-left h4 {
    padding: 30px 0 0 !important;
  } */
  /* .welcome-right {
    height: 444px;
  } */
  section.Buyerwlcmid-main {
    max-width: 100%;
  }
}
.coomn-bgb {
  height: 50px;
}
